export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_BACKEND = process.env.REACT_APP_API_BACKEND;

export const API_TRADING = API_HOST + "/trading/api";
export const AUTHENTICATE = API_BACKEND + "/authenticate";
export const ACTIVATE = API_BACKEND + "/verifyOTP";
export const RESENDOTP = API_BACKEND + "/resendOTP";
export const FORGOT_PASSWORD = API_BACKEND + "/forgotpassword";
export const SET_PASSWORD = API_BACKEND + "/setPassword";
export const VERIFY = API_BACKEND + "/checkActive";
export const ACTIVE_ACCOUNT = API_BACKEND + "/activeAccount";
export const BOTTOM_ICON_API = API_HOST + "/icon/getIcon";

export const REGISTER = API_BACKEND + "/register";
export const GET_COINS_PRICE = API_BACKEND + "/getCoinPrices";
export const POST_USERS_WALLETS = API_BACKEND + "/infoWallet/app";
export const POST_USERS_NOTIFICATION = API_BACKEND + "/notification/app";
export const POST_PLUGINS = API_BACKEND + "/getPlugins/app";
export const POST_USERS_CHANGE_EMAIl = API_BACKEND + "/changeEmail/users";
export const POST_USERS_CHANGE_PASSWORD = API_BACKEND + "/changePassword/users";
export const POST_USERS_CHANGE_MOBILE = API_BACKEND + "/changeMobile/users";
export const POST_USERS_CHANGE_AVATAR = API_BACKEND + "/changeAvatar/users";
export const POST_DELETE_USER = API_BACKEND + "/removeAccount/users";

export const WITHDRAW_TRANSACTION = API_BACKEND + "/add/transaction";
export const GET_FEE = API_BACKEND + "/fee/transaction";
export const POST_HISTORY_TRANSACTIONS = API_BACKEND + "/user/transaction";
export const UPLOAD_MEDIA = API_BACKEND + "/upload/media";
//Tradings
export const GET_TRADING_REQUESTS = API_TRADING + '/getOrders';
export const GET_TRADING_HISTORY = API_TRADING + '/getOrdersHistory';
export const OTP_ORDER = API_TRADING + '/otp';
export const BUY_ORDER = API_TRADING + '/buy';
export const BUY_REQUEST = API_TRADING + '/buyRequest';
export const SELL_REQUEST = API_TRADING + '/sellRequest';
export const GET_TRADING_CONFIGS = API_TRADING + '/getTradingConfigs';
export const CANCEL_TRADING = API_TRADING + '/cancel';
export const GET_REPORT_BY_COIN = API_TRADING + '/getReportByCoin';
//Profile
export const PROFILE_ENDPOINT = API_HOST + '/profile';
//P2P 
export const P2P_ENDPOINT = API_HOST + '/p2p/api';
export const GAME_DEPOSIT = API_HOST + "/game/transfer";
export const GAME_WITHDRAW = API_HOST + "/game/withdraw";
export const GAME_HISTORY = API_HOST + "/game/history";

import React from "react";
function InputText(props) {
    const [value, setValue] = React.useState(props.value ?? '');
    const onTextChange = (e) => {
        setValue(e.target.value)
        props.onChange(e.target.value);
    };

    return (
        <div className="form-group">
            <label className="label-control"> {props.label} </label>
            {props.lines ? (
                <textarea lines={props.lines} className={`input-control text-black p-2 ${props.disabled ? 'bg-gray-400' : 'bg-white'}`} type={props.type} value={value} onChange={onTextChange} required={props.required ?? false} placeholder={props.placeholder} disabled={props.disabled ?? false}> </textarea>
            ) : (
                <input className={`input-control text-black pt-5 pb-5 ${props.disabled ? 'bg-gray-400' : 'bg-white'}`} type={props.type} value={value} onChange={onTextChange} required={props.required ?? false} placeholder={props.placeholder} disabled={props.disabled ?? false} />
            )}
        </div>
    )
}
export default InputText;
import "../styles/App.css";
import "../styles/Main.css";
import React, { useContext, useEffect } from "react";
import Header from "../components/Header";
import TradingContainer from "../components/TradingContainer";
import TradingContainerDesktop from "../components/TradingContainerDesktop";
import useRouter from "../hooks/useRouter";
import { AuthContext } from "../App";
import { userActions } from "../reducer/userReducer";
import { BrowserView, MobileView } from "react-device-detect";
import { Cookies } from "react-cookie";
import { DEFAULT_COOKIE_ACCESS_TOKEN } from "../constants";
import HeaderDesktop from "../components/HeaderDesktop";
import { LoginPage } from ".";
import CoinInfo from "../components/CoinInfo";

function HomePage() {
  const cookies = new Cookies();
  const { query } = useRouter();
  const { access_token } = query;

  const { state, dispatch } = useContext(AuthContext);
  const { token, theme } = state.user;

  useEffect(
    () =>
      dispatch({
        type: userActions.setToken,
        data: {
          token: access_token ?? cookies.get(DEFAULT_COOKIE_ACCESS_TOKEN) ?? "",
        },
      }),
    [access_token, dispatch],
  );

  if (!token) return <LoginPage />;
  return (
    <div className={`App ${theme}-theme`}>
      <MobileView>
        <Header />
        <TradingContainer />
      </MobileView>
      <BrowserView>
        <HeaderDesktop />
        <CoinInfo />
        <TradingContainerDesktop />
      </BrowserView>
    </div>
  );
}

export default HomePage;

import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../App'
import { userActions } from '../../reducer/userReducer';

export const OptionsDropdown = () => {
    const { dispatch } = useContext(AuthContext);
    const options = [
        { key: 'home', value: 'Dashboard' },
        { key: 'topup', value: 'Deposit' },
        { key: 'withdraw', value: 'Withdraw' },
        { key: 'sign-out', value: 'Sign out' },
    ]
    const [value, setValue] = useState(options[0].key)
    
    useEffect(() => {
        const url = window.location.href;
        options.map((opt) => {
            if (url.indexOf(opt.key) > -1) setValue(opt.key);
        });
    }, []);

    const logout = () => {
        dispatch({
            type: userActions.logOut,
            data: {}
        })
    }
    const onChangeAction = (value) => {
        console.log(value);
        switch (value) {
            case 'sign-out':
                logout();
                break;
            case 'home':
                window.location.href = "/";
                break;
            case 'topup':
            case 'withdraw':
                window.location.href = `/${value}`;
                break;
            default:
                break;
        }
    }

    return (
        <button className="connect-button setting-dropdown" scale="md">
            <select className="select-coin" onChange={(e) => onChangeAction(e.target.value)} value={value}>
                {options.map((opt) => <option value={opt.key} key={opt.key}>{opt.value}</option>)}
            </select>
        </button>
    )
}
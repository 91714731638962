import { useEffect, useState, useContext } from "react";
import { TradingSelects, TradingActions } from "../../constants";
import { post } from "../../fetcher/request";
import { AuthContext } from "../../App";
import { BUY_REQUEST, SELL_REQUEST } from "../../fetcher/endpoints";
import swal from "sweetalert";
import { fetchUserInfo } from "../../fetcher";
import { userActions } from "../../reducer/userReducer";
import { PlusIcon, MinusIcon } from "../../icons/svg";

const FormAction = ({
  marketPrice,
  selectMarket,
  action,
  networkFee,
  token,
  price,
  setPrice,
}) => {
  const { state, dispatch } = useContext(AuthContext);
  const { selectCoin, wallet } = state.user;

  const [total, setTotal] = useState();

  const incrementPrice = () => {
    if (TradingSelects.Market !== selectMarket)
      setPrice((prev) => parseInt(prev ?? 0) + 1);
  };
  const decrementPrice = () => {
    if (TradingSelects.Market !== selectMarket && price > 0) {
      setPrice((prev) => parseInt(prev ?? 0) - 1);
    }
  };
  const incrementTotal = () => {
    setTotal((prev) => parseInt(prev ?? 0) + 1);
  };
  const decrementTotal = () => {
    if (total > 0) {
      setTotal((prev) => parseInt(prev ?? 0) - 1);
    }
  };

  const loadWallet = async () => {
    const data = await fetchUserInfo();
    const { wallet } = data;
    if (wallet) {
      dispatch({
        type: userActions.updateWallet,
        data: {
          wallet: wallet,
        },
      });
    }
  };
  const onAction = async () => {
    try {
      const data = {
        coin: selectCoin,
        newp: total,
        price: price,
      };

      setTotal(0);
      if (action === TradingActions.Buy || action === TradingActions.Sell) {
        const { success, msg } = await post(
          action === TradingActions.Buy ? BUY_REQUEST : SELL_REQUEST,
          data,
          token,
        );
        if (success) {
          swal({
            title: "Success",
            text: "Success",
            icon: "success",
          });
          loadWallet();
        } else {
          swal({
            title: "Error",
            text: msg,
            icon: "error",
          });
        }
      }
    } catch {
      setTotal(0);
      swal({
        title: "Error",
        text: "Fail Trasactions",
        icon: "error",
      });
    }
  };
  useEffect(() => {
    if (selectMarket === TradingSelects.Market) {
      setPrice(marketPrice ?? 0);
    }
  }, [selectMarket]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="form-control-trading">
        <span className="icon-left cursor-pointer" onClick={decrementPrice}>
          <MinusIcon />
        </span>
        <input
          disabled={TradingSelects.Market === selectMarket}
          placeholder="Price"
          type="text"
          className="input-trading"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <span className="icon-right cursor-pointer" onClick={incrementPrice}>
          <PlusIcon />
        </span>
      </div>
      <div className="form-control-trading">
        <span className="icon-left cursor-pointer" onClick={decrementTotal}>
          <MinusIcon />
        </span>
        <input
          placeholder="Amount"
          type="text"
          className="input-trading"
          value={total}
          onChange={(e) => setTotal(e.target.value)}
        />
        <span className="icon-right cursor-pointer" onClick={incrementTotal}>
          <PlusIcon />
        </span>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <p className="text-left text-header font-small">Available balance</p>
        {action === TradingActions.Buy && (
          <p className="text-right text-header font-small text-green-600">
            {wallet[selectCoin.toLowerCase()]} {selectCoin}
          </p>
        )}
        {action === TradingActions.Sell && (
          <p className="text-right text-header font-small">
            {`${wallet.newp} NEWP`}{" "}
          </p>
        )}
      </div>
      <div className="grid grid-cols-2 gap-2">
        <p className="text-left text-header font-small">Total</p>
        {total > 0 && price > 0 && (
          <p className="text-right text-header font-small ">
            {parseInt(total * price * 100) / 100} {selectCoin}
          </p>
        )}
      </div>
      <div className="grid grid-cols-2 gap-2 mt-5">
        <p className="text-left text-header font-small">Network fee</p>
        <p className="text-right text-header font-small">
          {" "}
          {networkFee} TRX <br /> (~$0.15 USDT)
        </p>
      </div>
      <button onClick={onAction} className="btn submit-btn">
        {action.toUpperCase()}
      </button>
    </form>
  );
};
export default FormAction;

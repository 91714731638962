import { func, number } from "prop-types";
import React, { useEffect, useState } from "react";
import { fetchP2PRequestById, unlockP2POrder } from "../../../fetcher";
import swal from "sweetalert";
import InfoText from "../../InfoText";
import { p2pOrderView } from "../../../constants";
import OrderForm from "../form/order";
import { API_HOST } from "../../../fetcher/endpoints";

const P2PDetailRequest = ({ requestId, onBack }) => {
  const [request, setRequest] = useState({});
  const [orders, setOrders] = useState([]);
  const [orderView, setOrderView] = useState(""); // form , list
  const loadData = async () => {
    const { success, data, msg } = await fetchP2PRequestById({
      id: requestId,
    });
    if (success) {
      setRequest(data);
      setOrders(data.orders);
      setOrderView(data.view);
    } else {
      swal({
        title: "Request",
        text: msg,
        icon: "error",
      });
    }
  };

  const unlock = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Do you want to unlock this order ? ")) {
      const { success, msg } = await unlockP2POrder({
        id: id,
      });
      if (success) {
        swal({
          title: "Unlock",
          text: "Success",
          icon: "success",
        });
        loadData();
      } else {
        swal({
          title: "Unlock",
          text: msg,
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (requestId) {
      loadData();
    }
  }, [requestId, orderView]);

  return (
    <div className="p-2 h-full">
      <div className="flex">
        <button className="submit-btn" onClick={() => onBack()}>
          Back to List
        </button>
      </div>
      <div className="trading-btn flex flex-row p-8">
        <div
          className="flex p-4 text-left flex-col"
          style={{ borderWidth: 1, borderColor: "#ffffff80" }}
        >
          <p className="text-3xl font-bold border-b mb-4">Request Information </p>
          <InfoText
            className={"font-bold"}
            label="Email"
            value={request.user_email}
          />
          <InfoText className={"font-bold"} label="Note" value={request.name} />
          <InfoText
            className={"font-bold"}
            label={`Total ${request.action} `}
            value={`${request.quantity} ${request.coin}`}
          />
          <InfoText
            label="Price"
            className={"font-bold"}
            value={request.price + " " + request.currency}
          />
          <InfoText
            label="Bank Name"
            className={"font-bold"}
            value={request.bank_name}
          />
          <InfoText
            label="Bank Account"
            className={"font-bold"}
            value={request.bank_account}
          />
        </div>
        <div className="flex flex-1 p-4">
          {orderView === p2pOrderView.List && (
            <table className=" monitor-table p-10 w-full">
              <thead>
                <tr>
                  <td className="text-header">Email</td>
                  <td className="text-header">Total</td>
                  <td className="text-header">Note</td>
                  <td className="text-header">Image1</td>
                  <td className="text-header">Image2</td>
                  <td className="text-header">Time</td>
                  <td className="text-header">Action</td>
                </tr>
              </thead>
              <tbody>
                {orders.length === 0 && (
                  <tr>
                    <td colSpan={6}>
                      <p className="text-center my-2 pt-4"> No Records </p>
                    </td>
                  </tr>
                )}
                {orders.map((item) => (
                  <tr key={item.id} style={{ height: 25 }}>
                     <td
                      className={`${
                        item.status === "1" ? "text-success" : "text-danger"
                      }`}
                    >
                      {item.user_email}
                    </td>
                    <td
                      className={`${
                        item.status === "1" ? "text-success" : "text-danger"
                      }`}
                    >
                      {item.total} {item.coin}
                    </td>
                    <td
                      className={`${
                        item.status === "1" ? "text-success" : "text-danger"
                      }`}
                    >
                      {item.note}
                    </td>
                    <td
                      className={`${
                        item.status === "1" ? "text-success" : "text-danger"
                      }`}
                    >
                      {item.image1 && (
                        <a
                          target="_blank"
                          href={`${API_HOST}${item.image1}`}
                          rel="noreferrer"
                        >
                          <img src={`${API_HOST}${item.image1}`} alt="image1" width={50} />
                        </a>
                      )}
                    </td>
                    <td
                      className={`${
                        item.status === "1" ? "text-success" : "text-danger"
                      }`}
                    >
                      {item.image2 && (
                        <a
                          target="_blank"
                          href={`${API_HOST}${item.image2}`}
                          rel="noreferrer"
                        >
                          <img src={`${API_HOST}${item.image2}`} alt="image2" width={50} />
                        </a>
                      )}
                    </td>
                    <td
                      className={`${
                        item.status === "1" ? "text-success" : "text-danger"
                      }`}
                    >
                      {item.datetime_created}
                    </td>
                    <td>
                      {item.status === "1" && request.allowUnlock === 1 && (
                        <button
                          className="btn-p2p-action"
                          onClick={() => unlock(item.id)}
                        >
                          Unlock
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {orderView === p2pOrderView.Form && (
            <div className="flex flex-1 text-center align-middle justify-center items-center">
              <OrderForm
                request_id={requestId}
                coin={request.coin}
                onSubmit={() => setOrderView(p2pOrderView.List)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

P2PDetailRequest.prototype = {
  requestId: number,
  onBack: func,
};
export default P2PDetailRequest;

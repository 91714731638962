export const userActions = {
  setToken: "USERS_SET_TOKEN",
  updateWallet: "USERS_UPDATE_WALLET",
  setTheme: "USERS_SET_THEME",
  logOut: "USERS_LOG_OUT",
  setSelectCoin: "USERS_SELECT_COIN",
  setWalletCoin: "USERS_SELECT_WALLET_COIN",
  setConfig: "USERS_SET_CONFIG",
};

export const initialUserState = {
  token: '',
  userInfo: null,
  selectCoin: 'USDT',
  walletCoin: 'USDT',
  wallet: {
    newp: 0,
    newp_img: 'https://www.newex.me/theme/img/trader/newp-icon.png',
    usdt: 0,
    usdt_img: 'https://www.newex.me/theme/img/trader/usdt-icon.png',
    trx: 0,
    trx_img: 'https://www.newex.me/theme/img/trader/TRX.png',
    trxUSD: 0,
    newpUSD: 0,
    address: '',
    xuNEWP: 0,
  },
  configs: {},
  theme: 'dark'
};
export const userReducer = (state, action) => {
  const { type, data } = action;
  switch (type) {
    case userActions.setToken:
      const { token } = data;
      return {
        ...state,
        token: token,
      };
    case userActions.updateWallet:
      const { wallet } = data;
      const trx = (wallet ?? []).filter(item => item.code === 'TRX');
      const newp = (wallet ?? []).filter(item => item.code === 'NEWP');
      const usdt = (wallet ?? []).filter(item => item.code === 'USDT');
      const xu = (wallet ?? []).filter(item => item.code === 'xuNEWP');
      return {
        ...state,
        wallet: {
          ...state.wallet,
          trx: trx[0].price ?? 0,
          trxUSD: trx[0].price_usd ?? 0,
          newp: newp[0].price ?? 0,
          newpUSD: newp[0].price_usd ?? 0,
          usdt: usdt[0].price ?? 0,
          address: newp[0].receive_address,
          xuNEWP: xu[0].price ?? 0,
        },
      };
    case userActions.setTheme:
      const { theme } = data;
      return {
        ...state,
        theme: theme
      }
    case userActions.setConfig:
      const { configs } = data;
      return {
        ...state,
        configs: configs
      }
    case userActions.setSelectCoin:
      const { coin } = data;
      return {
        ...state,
        selectCoin: coin
      }
    case userActions.setWalletCoin:
      const { walletCoin } = data;
      return {
        ...state,
        walletCoin: walletCoin
      }
    case userActions.logOut:
      return {
        ...state,
        token: ''
      }
    default:
      return state;
  }
}
import { useContext } from 'react';
import { AuthContext } from '../../App';
import { Divider } from '../icon';

export default function WalletInfo() {
    const { state } = useContext(AuthContext);
    const { wallet, walletCoin } = state.user;

    return (
        <div className='coin-info'>
            <div className='coin-price'>
                <div className="trading-header-center-row p-2 gap-2">
                    {walletCoin === 'USDT' && (
                        <div className='flex flex-row space-x-2 text-center justify-center align-middle items-center'>
                            <img src={wallet.usdt_img} alt='logo-icon' className="coin-icon " />
                            <b className="coin-text">{wallet.usdt} &nbsp;
                                <small><b>USDT</b></small>
                            </b>
                        </div>
                    )}
                    {walletCoin === 'TRX' && (
                        <div className='flex flex-row space-x-2 text-center justify-center align-middle items-center'>
                            <img src={wallet.trx_img} alt='logo-icon' className="coin-icon " />
                            <b className="coin-text">{wallet.trx} &nbsp;
                                <small><b>TRX</b></small>
                            </b>
                        </div>
                    )}
                    {walletCoin === 'NEWP' && (
                        <div className='flex flex-row space-x-2 text-center justify-center align-middle items-center'>
                            <img src={wallet.newp_img} alt='logo-icon' className="coin-icon " />
                            <b className="coin-text">{wallet.newp} &nbsp;
                                <small><b>NEWP</b></small>
                            </b>
                        </div>
                    )}
                </div>
            </div>
            <div className='coin-data'>
                <div className="TradeRatesCard__RatesCardsWrapper-sc-d8v0i9-2 cCrbLl">
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="foregroundLowEmphasis" className="Text-sc-d4qk04-0 iosmNE">24h Change</div>
                        <div color="dangerHighEmphasis" className="Text-sc-d4qk04-0 jhXiok text-green-700">+0.006 | +20%</div>
                    </div>
                    <Divider />
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="foregroundLowEmphasis" className="Text-sc-d4qk04-0 iosmNE">24h High</div>
                        <div color="foregroundHighEmphasis" className="Text-sc-d4qk04-0 jhXiok">0.1</div>
                    </div>
                    <Divider />
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="foregroundLowEmphasis" className="Text-sc-d4qk04-0 iosmNE">24h Low</div>
                        <div color="foregroundHighEmphasis" className="Text-sc-d4qk04-0 jhXiok">0.048</div>
                    </div>
                    <Divider />
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="foregroundLowEmphasis" className="Text-sc-d4qk04-0 iosmNE">24h Volume(TRX)</div>
                        <div color="foregroundHighEmphasis" className="Text-sc-d4qk04-0 jhXiok">0,00</div>
                    </div>
                    <Divider />
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="foregroundLowEmphasis" className="Text-sc-d4qk04-0 iosmNE">24h Volume(USDT)</div>
                        <div color="foregroundHighEmphasis" className="Text-sc-d4qk04-0 jhXiok">2,253,381</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useState } from "react";
import P2PForm from "./form";
import P2PRequest from "./request";
import P2POrder from "./order";
import P2PMyRequest from "./my_request";
import { p2pTab, p2pView } from "../../constants";
import P2PDetailRequest from "./detail/request";

function P2PContainer() {
    const [tab, setTab] = useState(p2pTab.MyRequest);
    const [view, setView] = useState(p2pView.List)
    const [requestId,  setRequestId] = useState(0);

    const onSubmit = (flag) => {
        setTab("");
        setTimeout(() => {
            setTab(p2pTab.MyRequest);
        }, 300)
    }
    const onDetailOrder = (id) => {
        setView(p2pView.Request);
        setRequestId(id);
    }
    const onDetailRequest = (id) => {
        setView(p2pView.Request);
        setRequestId(id);
    }
    return (
        <div className="trading-container flex h-screen pt-[110px]">
            {view === p2pView.Request && <P2PDetailRequest requestId={requestId} onBack={() => setView(p2pView.List)}  />}
            {view === p2pView.List && (
                <div className="trading-monitor h-100 p-4">
                    <div className="grid grid-cols-4 w-full space-x-12">
                        <P2PForm onSubmit={onSubmit} />
                        <div className=" col-span-3 ">
                            <div className="trading-select m-t-20 p-4 space-x-6">
                                <button onClick={() => setTab(p2pTab.MyRequest)} className={`selects-btn ${p2pTab.MyRequest === tab ? 'select-active' : ''}`}>Your Request</button>
                                <button onClick={() => setTab(p2pTab.MyOrder)} className={`selects-btn ${p2pTab.MyOrder === tab ? 'select-active' : ''}`}>Your Order</button>
                                <button onClick={() => setTab(p2pTab.Open)} className={`selects-btn ${p2pTab.Open === tab ? 'select-active' : ''}`}>Transactions</button>
                            </div>
                            <div className="p-4 min-h-[500px]">
                                {tab === p2pTab.MyRequest && <P2PMyRequest onDetailRequest={onDetailRequest} />}
                                {tab === p2pTab.MyOrder && <P2POrder onDetailOrder={onDetailOrder} />}
                                {tab === p2pTab.Open && <P2PRequest onDetailRequest={onDetailRequest}/>}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default P2PContainer;
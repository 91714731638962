const getQueryString = (queries) => {
    return Object.keys(queries).reduce((result, key) => {
        return [...result, `${encodeURIComponent(key)}=${encodeURIComponent(queries[key])}`]
    }, []).join('&');
};

export const get = async (url, param) => {
    const response = await fetch(url + "?" + getQueryString(param), {
        method: 'GET'
    });
    try {
        const json = await response.json();
        return json;
    } catch {
        return {};
    }
}


export const post = async (url, data, token) => {
    const options = {
        method: 'POST',
        body: JSON.stringify({ ...data, token: token }),
    }

    const response = await fetch(url, options);
    try {
        const json = await response.json();
        return json;
    } catch {
        return {};
    }
}
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import { updatePassword } from "../../fetcher";
import InputText from "../InputText";
import swal from "sweetalert";

const ProfileChangePassword = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { state } = useContext(AuthContext);
  const { token } = state.user;

  const onSubmit = async () => {
    if (password !== confirmPassword) {
      swal({
        title: "Update",
        text: "Can not update",
        icon: "error",
      });
      return false;
    }

    setLoading(true);
    const { success } = await updatePassword(
      {
        password: password,
      },
      token,
    );
    if (success) {
      swal({
        title: "Update",
        text: "Success",
        icon: "success",
      });
      setPassword("");
      setConfirmPassword("");
    } else {
      swal({
        title: "Update",
        text: "Can not update",
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    !loading && (
      <div className="flex flex-row" style={{ width: 500, margin: "auto" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="form-control"
        >
          <InputText
            label="Password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(value) => setPassword(value)}
            required={true}
          />
          <InputText
            label="Confirm Password"
            placeholder="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(value) => setConfirmPassword(value)}
            required={true}
          />
          <button
            className="btn success-btn my-10"
            type="submit"
            disabled={loading}
          >
            Update
          </button>
        </form>
      </div>
    )
  );
};

export default ProfileChangePassword;

import React from "react";

const MinusIcon = () => {
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      className="Svg-sc-10nuzln-0 botfKF"
    >
      <path
        d="M9.33382 1.6668H0.000488281V0.333466H9.33382V1.6668Z"
        fill="#FFFFFF"
      ></path>
    </svg>
  );
};

const PlusIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      className="Svg-sc-10nuzln-0 botfKF"
    >
      <path
        d="M9.33382 5.6668H5.33382V9.6668H4.00049V5.6668H0.000488281V4.33347H4.00049V0.333466H5.33382V4.33347H9.33382V5.6668Z"
        fill="#FFFFFF"
      ></path>
    </svg>
  );
};

export {
  MinusIcon,
  PlusIcon,
};

import React, { useEffect, useState } from 'react'
import { func, number } from 'prop-types'

const Pagination = ({ page, pages, total, handleChangePaging }) => {
    const [_page, setPage] = useState(page);
    const [_total, setTotal] = useState(total);
    
    useEffect(() => {
        handleChangePaging(_page, _total)
    }, [_page])
    
    return (
        <div className='flex flex-row justify-center items-center space-x-4' >
            <div className="flex justify-center align-middle  items-center ">
                <button className={` ${_page > 1 ? 'paging-btn': 'disabled-btn'}`} disabled={_page === 1} onClick={() => setPage((prev) => prev - 1)}>Prev</button>
            </div>
            <div className='flex pt-2'>
                <p className='label-control'> Page: {_page} / { pages }</p>
            </div>
            <div className="flex justify-center align-middle items-center ">
                <button className={` ${_page < pages ? 'paging-btn' : 'disabled-btn'}`}  disabled={_page === pages} onClick={() => setPage((prev) => prev + 1)}>Next</button>
            </div>
        </div>    
    )   
}

Pagination.prototype = {
    page: number,
    total: number,
    limit: number,
    handleChangePaging: func,
}

export default Pagination;
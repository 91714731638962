import React from "react";

const SelectInput = (props) => {
    const [value, setValue] = React.useState(props.value ?? '');
    const onChange = (e) => {
        setValue(e.target.value);
        props.onChange(e.target.value);
    };

    return (
        <div className="form-group">
            <label className="label-control"> {props.label} </label>
            <select value={value} onChange={onChange} className={props.className ? props.className : "p-2 rounded shadow"}>
                < option > Select</option>
                {
                    props.options.map((option) => <option key={`${option.id}`} value={option.value}>{option.label}</option>)
                }
            </select >
        </div >
    )
}
export default SelectInput;
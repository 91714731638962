import React, { useContext, useMemo } from "react";
import { AuthContext } from "../../App";

function DashboardContainer() {
    const { state } = useContext(AuthContext);
    const { wallet } = state.user;

    const calcTotal = useMemo(() => {
        return (parseFloat(wallet.newpUSD) + parseFloat(wallet.usdt) + parseFloat(wallet.trxUSD)).toFixed(2);
    }, [wallet])
    return (
        <div className="trading-container flex pt-[40px] h-screen">
            <div className="p-20 w-full">
                <h3 className="text-white text-left">Estimated Balance</h3>
                <h2 className=" text-amber-600 font-bold text-left my-4">$ {calcTotal}</h2>

                <h3 className="text-white text-left mb-4">Dashboard</h3>
                <table className="monitor-table p-10">
                    <tbody>
                        <tr className="h-[50px] bg-slate-600">
                            <td className="text-white w-[120px] pl-4">Coin</td>
                            <td className="text-white w-[150px]">Total</td>
                            <td className="text-white w-[300px]">USD ($)</td>
                            <td className="text-white w-[200px]">Action</td>
                        </tr>
                        <tr className="border-t border-b h-[50px]">
                            <td className="pl-4">USDT</td>
                            <td>{wallet.usdt}</td>
                            <td>${wallet.usdt}</td>
                            <td>
                                <div className="flex flex-row space-x-2">
                                    <a href='/topup?coin=usdt'>Deposit</a>
                                    <a className="text-orange-500" href='/withdraw?coin=usdt'>Withdraw</a>
                                </div>
                            </td>
                        </tr>
                        <tr className="border-t border-b h-[50px]">
                            <td className="pl-4">NEWP</td>
                            <td>{wallet.newp}</td>
                            <td>${wallet.newpUSD.toFixed(2)}</td>
                            <td>
                                <div className="flex flex-row space-x-2">
                                    <a href='/topup?coin=newp'>Deposit</a>
                                    <a className="text-orange-500" href='/withdraw?coin=newp'>Withdraw</a>
                                </div>
                            </td>
                        </tr>
                        <tr className="border-t border-b h-[50px]">
                            <td className="pl-4">TRX</td>
                            <td>{wallet.trx}</td>
                            <td>${wallet.trxUSD.toFixed(2)}</td>
                            <td>
                                <div className="flex flex-row space-x-2">
                                    <a href='/topup?coin=trx'>Deposit</a>
                                    <a className="text-orange-500" href='/withdraw?coin=trx'>Withdraw</a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default DashboardContainer;
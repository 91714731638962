import React, { useState } from "react";
import * as props from "prop-types";
import InputText from "../../InputText";
import { coinSelectOptions } from "../../../constants";
import SelectInput from "../../Form/SelectInput";

const BuyForm = ({ onSubmit, data }) => {
  const [value, setValue] = useState(data);
  const validate = () => {
    submit();
  };
  const submit = () => {
    if (onSubmit) {
      onSubmit({ ...value, action: "buy" });
    }
  };
  return (
    <>
      <form
        className="text-left"
        onSubmit={(e) => {
          e.preventDefault();
          validate();
        }}
      >
        <InputText
          label="Note"
          placeholder="I want to buy ..."
          type="text"
          value={value.name}
          onChange={(text) => setValue({ ...value, name: text })}
        />
        <InputText
          label="Quantity"
          placeholder="1,000"
          type="text"
          value={value.quantity}
          onChange={(text) => setValue({ ...value, quantity: text })}
        />
        <SelectInput
          label="Select Coin"
          options={coinSelectOptions}
          placeholder="Select coin"
          value={value.coin}
          onChange={(text) => setValue({ ...value, coin: text })}
        />
        <div className="grid grid-cols-2 space-x-2">
          <InputText
            label="Price"
            placeholder="Price"
            type="number"
            value={value.price}
            onChange={(text) => setValue({ ...value, price: text })}
          />
          <InputText
            label="Currency"
            placeholder="Currency"
            type="text"
            value={value.currency}
            onChange={(text) => setValue({ ...value, currency: text })}
          />
        </div>
        <button type="submit" className="btn submit-btn">
          BUY
        </button>
      </form>
    </>
  );
};

BuyForm.prototype = {
  onSubmit: props.func,
};
export default BuyForm;

import { AUTHENTICATE, GET_FEE, POST_USERS_WALLETS, REGISTER, WITHDRAW_TRANSACTION, POST_HISTORY_TRANSACTIONS, ACTIVE_ACCOUNT, CANCEL_TRADING, PROFILE_ENDPOINT, FORGOT_PASSWORD, P2P_ENDPOINT, SET_PASSWORD, GAME_DEPOSIT, GAME_WITHDRAW, GAME_HISTORY } from "./endpoints";
import { get, post } from "./request";
import { Cookies } from "react-cookie";
import { DEFAULT_COOKIE_ACCESS_TOKEN } from "../constants";
const ACCESS_TOKEN = new Cookies().get(DEFAULT_COOKIE_ACCESS_TOKEN);

export const login = async (params) => {
    const data = await post(AUTHENTICATE, params);
    return data;
}

export const register = async (params) => {
    const data = await post(REGISTER, {
        app_register: true,
        username: params.email,
        email: params.email,
        phone: params.phone,
        password: params.password,
    });
    return data;
}


export const forgotPassword = async (params) => {
    const data = await post(FORGOT_PASSWORD, {
        email: params.email,
    });
    return data;
}

export const setForgotPassword = async (params) => {
    const data = await post(SET_PASSWORD, {
        password: params.password,
        access_token: params.token,
    });
    return data;
}

export const fetchUserInfo = async () => {
    const data = await post(POST_USERS_WALLETS, {}, ACCESS_TOKEN);
    return data;
}

export const depositGame = async ({ total }) => {
    const result = await post(GAME_DEPOSIT, {
        total: total,
    }, ACCESS_TOKEN);
    return result;
}

export const withdrawGame = async ({ total }) => {
    const result = await post(GAME_WITHDRAW, {
        total: total,
    }, ACCESS_TOKEN);
    return result;
}

export const fetchHistoriesGame = async (page) => {
    const result = await post(GAME_HISTORY, {
        page: page
    }, ACCESS_TOKEN);
    return result;
}

export const withdraw = async ({ address, type, coin, total, authenCode }) => {
    const result = await post(WITHDRAW_TRANSACTION, {
        type: type,
        coin: coin,
        out: total,
        to: address,
        authenCode: authenCode
    }, ACCESS_TOKEN);
    return result;
}

export const getFee = async (coin, address) => {
    const result = await post(GET_FEE, {
        coin: coin,
        address: address
    }, ACCESS_TOKEN);
    return result;
}

export const getTransactions = async (coin, type) => {
    const result = await post(POST_HISTORY_TRANSACTIONS, {
        page: 1,
        limit: 100,
        type: type,
        coin: coin
    }, ACCESS_TOKEN);
    return result;
}

export const activate = async (access_token) => {
    const result = await post(ACTIVE_ACCOUNT, { access_token: access_token}, ACCESS_TOKEN);
    return result;
}

export const cancelItem = async (id) => {
    const result = await post(CANCEL_TRADING, { id }, ACCESS_TOKEN);
    return result;
}

export const getProfile = async () => {
    const result = await get(`${PROFILE_ENDPOINT}/get`, {token : ACCESS_TOKEN });
    return result;
}

export const updateProfile = async (params) => {
    const result = await post(`${PROFILE_ENDPOINT}/update`, params, ACCESS_TOKEN);
    return result;
}

export const updatePassword = async (params) => {
    const result = await post(`${PROFILE_ENDPOINT}/updatePassword`, params, ACCESS_TOKEN);
    return result;
}

export const enable2FA = async () => {
    const result = await post(`${PROFILE_ENDPOINT}/generateGACode`, {}, ACCESS_TOKEN);
    return result;
}

export const disable2FA = async () => {
    const result = await post(`${PROFILE_ENDPOINT}/disable2fa`, {}, ACCESS_TOKEN);
    return result;
}

export const auth2FA = async (data) => {
    const result = await post(`${PROFILE_ENDPOINT}/authGACode`, data, ACCESS_TOKEN);
    return result;
}

export const updateBankInfo = async (data) => {
    const result = await post(`${PROFILE_ENDPOINT}/updateBankInfo`, data, ACCESS_TOKEN);
    return result;
}

export const createP2PRequest = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/addRequest`, data, ACCESS_TOKEN);
    return result;
}

export const createP2POrder = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/addOrder`, data, ACCESS_TOKEN);
    return result;
}

export const fetchMyP2PRequests = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/getTradingsByUserId`, data , ACCESS_TOKEN);
    return result;
}

export const fetchMyP2POrders = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/getOrdersByUserId`, data , ACCESS_TOKEN);
    return result;
}

export const fetchOpenP2PRequests = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/getOpenTradingsByUserId`, data , ACCESS_TOKEN);
    return result;
}

export const cancelP2PRequest = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/cancelRequest`, data , ACCESS_TOKEN);
    return result;
}

export const fetchP2POrderById = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/getP2POrderById`, data , ACCESS_TOKEN);
    return result;
}

export const fetchP2PRequestById = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/getP2PRequestById`, data , ACCESS_TOKEN);
    return result;
}

export const addP2POrder = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/addOrder`, data , ACCESS_TOKEN);
    return result;
}

export const unlockP2POrder = async (data) => {
    const result = await post(`${P2P_ENDPOINT}/unlock`, data, ACCESS_TOKEN);
    return result;
}
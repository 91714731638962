import { useEffect, useState } from "react";
import swal from "sweetalert";
import { initialBuyP2P, initialSellP2P, p2pAction } from "../../../constants";
import BuyForm from "./buy";
import SellForm from "./sell";
import { createP2PRequest } from "../../../fetcher";

const P2PForm = ({ onSubmit }) => {
  const [action, setAction] = useState(p2pAction.BUY);
  const [data, setData] = useState(initialBuyP2P);

  const submitRequest = async (value) => {
    const response = await createP2PRequest({ ...value, action: action });
    setData(null);
    if (response.success) {
      setData(action === p2pAction.BUY ? initialBuyP2P : initialSellP2P);
      swal({
        title: "Success",
        text: "Your request was sent",
        icon: "success",
      });
    } else {
      swal({
        title: "Error",
        text: response.msg,
        icon: "error",
      });
    }
    if (onSubmit) onSubmit(true);
  };

  useEffect(() => {
    setData(action === p2pAction.BUY ? initialBuyP2P : initialSellP2P);
  }, [action]);

  return (
    <div className="input-trading-container items-center self-center">
      <div className="trading-btn grid grid-cols-2 gap-2 w-100">
        <button
          onClick={() => setAction(p2pAction.BUY)}
          className={`actions-btn ${
            p2pAction.BUY === action ? "action-buy" : ""
          }`}
        >
          {p2pAction.BUY.toUpperCase()}
        </button>
        <button
          onClick={() => setAction(p2pAction.SELL)}
          className={`actions-btn ${
            p2pAction.SELL === action ? "action-sell" : ""
          }`}
        >
          {p2pAction.SELL.toUpperCase()}
        </button>
      </div>
      {p2pAction.BUY === action && data && (
        <BuyForm onSubmit={submitRequest} data={data} setData={setData} />
      )}
      {p2pAction.SELL === action && data && (
        <SellForm onSubmit={submitRequest} data={data} setData={setData} />
      )}
    </div>
  );
};
export default P2PForm;

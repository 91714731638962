import React, { useContext, useState } from "react";
import * as props from "prop-types";
import InputText from "../../InputText";
import UploadPhoto from "../../UploadPhoto";
import { AuthContext } from "../../../App";
import swal from "sweetalert";
import { addP2POrder } from "../../../fetcher";

const OrderForm = ({ request_id, coin, onSubmit }) => {
  const { state } = useContext(AuthContext)
  const { token } = state.user;
  const [value, setValue] = useState({
    p2p_id: request_id,
    coin: coin,
    total: 0,
    image1: '',
    image2: '',
  });
 
  const validate = () => {
    if(value.total <= 0) {
      return swal({
        text:'Total Required',
        icon:'error',
      });
    }
    if(!value.image1 && !value.image2) {
      return swal({
        text:'Attachment required',
        icon:'error',
      });
    }
    submit();
  };
  const submit = async () => {
    const { success, msg } = await addP2POrder(value);
    if(success) {
      if (onSubmit) {
        swal({
          title: 'P2P',
          text: 'Success',
          icon: 'success'
        })
        onSubmit(value);
      }
    }else {
      swal({
        title: 'P2P',
        text: msg,
        icon: 'error'
      })
    }
  };
  return (
    <div className="flex">
      <form
        className="text-left"
        onSubmit={(e) => {
          e.preventDefault();
          validate();
        }}
      >
        <InputText
          label="Total"
          placeholder="Total"
          type="number"
          value={value.total}
          onChange={(total) => setValue({ ...value, total: total })}
        />
        <InputText
          label="Note"
          placeholder="I want to .."
          type="text"
          lines={3}
          value={value.note}
          onChange={(text) => setValue({ ...value, note: text })}
        />
        <div className="grid grid-cols-2">
          <UploadPhoto
            title="Attachment 1"
            url={value.image1}
            onUpload={(img) => setValue({...value, image1: img })}
            token={token}
          />
          <UploadPhoto
            title="Attachment 1"
            url={value.image2}
            onUpload={(img) => setValue({ ...value, image2: img })}
            token={token}
          />
        </div>
        <button type="submit" className="btn submit-btn">
          Order
        </button>
      </form>
    </div>
  );
};

OrderForm.prototype = {
  onSubmit: props.func,
};
export default OrderForm;

import InputText from "../components/InputText";
import "../styles/App.css";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { setForgotPassword } from "../fetcher";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

function ResetPasswordPage() {
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    console.log(window.location.href);
    const urls = window.location.href.split("=");
    setToken(urls.length > 0 ? urls[1] : "");
  }, []);

  const { isLoading, mutate } = useMutation(setForgotPassword, {
    onSuccess: (data) => {
      const { success, msg } = data;
      if (success) {
        swal({
          title: "Success",
          text: "You have changed password successfully",
          icon: "success",
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      } else {
        swal({
          title: "Fail",
          text: msg,
          icon: "error",
        });
      }
    },
    onError: (err) => {
      swal({
        title: "Fail",
        text: "Unexpected Errors. Please contact the administrator",
        icon: "error",
      });
    },
  });
  const onSubmit = function (e) {
    mutate({ password: password, token: token});
  };

  return (
    <div className="login-container">
      <div className={`${!isMobile && "max-width-md"} center login-form`}>
        <img src="https://newex.me/theme/img/logo.png" alt="logo" />
        <p className="my-10 heading-text">Reset password</p>
        <p className="font-bold mt-11">Please enter your new password</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="form-control"
        >
          <InputText
            placeholder="Enter password"
            type="password"
            value={password}
            onChange={(value) => setPassword(value)}
            required={true}
          />
          <button className="btn submit-btn" type="submit" disabled={isLoading}>
            Submit
          </button>
          <p className="font-bold mt-11">
            Already have an account?{" "}
            <a className="App-link" href="/login" rel="noopener noreferrer">
              Sign in
            </a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordPage;

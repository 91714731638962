import React, { useEffect, useState } from "react";
import { fetchMyP2POrders } from "../../fetcher";
import Pagination from "../Pagination";
import { ICONS } from "../../config/icons";

const P2POrder = ({ onDetailOrder }) => {
  const [data, setData] = useState([]);
  const [paging, setPaging] = useState({
    total: 0,
    page: 1,
    pages: 1,
  });

  const loadP2POrder = async () => {
    const { success, data } = await fetchMyP2POrders({ page: paging.page });
    if (success) {
      const { records, paging } = data;
      setData(records);
      setPaging(paging);
    }
  }

  const onChangePaging = (page) => {
    setPaging({ ...paging, page: page });
  }
  useEffect(() => {
    loadP2POrder();
  }, [paging.page])

  return (
    <table className="monitor-table p-10 w-full">
      <thead>
        <tr>
          <td className="text-header">Total</td>
          <td className="text-header">Coin</td>
          <td className="text-header">Note</td>
          <td className="text-header">Time</td>
          <td className="text-header">Status</td>
          <td className="text-header">Action</td>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <tr>
            <td colSpan={6}>
              <p className="text-center my-2" style={{ margin: 10 }}>
                {" "}
                No Records{" "}
              </p>
            </td>
          </tr>
        )}
        {data.map((item) => (
          <tr key={item.id} style={{ height: 25 }}>
            <td
              className={`${
                item.status === "3" ? "text-danger" : "text-success"
              }`}
            >
              {item.total}
            </td>
            <td
              className={`${
                item.status === "3" ? "text-danger" : "text-success"
              }`}
            >
              <img
                src={ICONS[item.coin.toUpperCase()]}
                alt="logo-icon"
                width="15px"
              />
            </td>
            <td
              className={`${
                item.status === "3" ? "text-danger" : "text-success"
              }`}
            >
              {item.note}{" "}
            </td>
            <td
              className={`${
                item.status === "3" ? "text-danger" : "text-success"
              }`}
            >
              {item.datetime_created}
            </td>
            <td
              className={`${
                item.status === "3" ? "text-danger" : "text-success"
              }`}
            >
              {item.status_text}
            </td>
            <td>
              {item.status === "1" && (
                <button
                  className="btn-p2p-action"
                  onClick={() => onDetailOrder(item.p2p_id)}
                >
                  Detail
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={6}>
            <Pagination
              total={paging.total}
              page={paging.page}
              pages={paging.pages}
              limit={paging.limit}
              handleChangePaging={onChangePaging}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default P2POrder;

import { p2pAction } from "../constants";

export const p2pActions = {
  SET_ORDERS: 'P2P_SET_ORDERS',
  SET_OPEN_REQUESTS: 'P2P_SET_OPEN_REQUESTS',
  SET_REQUESTS: 'P2P_SET_REQUESTS',
};

export const initialP2PState = {
  yourOrders: [],
  openRequests: [],
  yourRequests: [],
};
export const p2pReducer = (state, action) => {
  const { type, data } = action;
  switch (type) {
    case p2pAction.SET_ORDERS:
      return {
        ...state, yourOrders: data.yourOrders,
      };
    case p2pAction.SET_OPEN_REQUESTS:
      return {
        ...state, openRequests: data.openRequests,
      };
    case p2pAction.SET_REQUESTS:
      return {
        ...state, yourRequests: data.yourRequests,
      };
    default:
      return state;
  }
}
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import { Divider, StarIcon } from '../icon';
import { post } from '../../fetcher/request';
import { GET_REPORT_BY_COIN } from '../../fetcher/endpoints';

export default function CoinInfo() {
    const { state } = useContext(AuthContext);
    const { wallet, theme, selectCoin, configs, token } = state.user;
    const [info, setInfo] = useState({});

    const loadInfo = async () => {
        const response = await post(GET_REPORT_BY_COIN, { coin: selectCoin }, token);
        if (response.success) {
            setInfo(response.data);
        }
    }

    useEffect(() => {
        loadInfo()
    }, []);

    return (
        <div className='coin-info'>
            <div className='flex flex-row coin-price'>
                <div className="trading-header-center-row p-2 gap-2">
                    {selectCoin === 'USDT' && (
                        <div className='flex flex-row text-center justify-center align-middle items-center space-x-2'>
                            <img src={wallet.usdt_img} alt='logo-icon' className="coin-icon flex" />
                            <b className="coin-text">{Number(wallet.usdt)} &nbsp;
                                <small><b>USDT</b></small>
                            </b>
                        </div>
                    )}
                    {selectCoin === 'TRX' && (
                        <div className='flex flex-row text-center justify-center align-middle items-center  space-x-2'>
                            <img src={wallet.trx_img} alt='logo-icon' className="coin-icon flex" />
                            <b className="coin-text">{Number(wallet.trx).toFixed(2)} &nbsp;
                                <small><b>TRX</b></small>
                            </b>
                        </div>
                    )}
                </div>
                <div className="trading-header-center-row p-2 gap-2">
                    <div className='flex flex-row text-center justify-center align-middle items-center space-x-2'>
                        <img src={wallet.newp_img} alt='logo-icon' className="coin-icon flex" />
                        <b className="coin-text">{Number(wallet.newp)} &nbsp;
                            <small><b>NEWP</b></small>
                        </b>
                    </div>
                </div>
            </div>

            <div className='coin-data'>
                <div className="TradeRatesCard__RatesCardsWrapper-sc-d8v0i9-2 cCrbLl">
                    <div className="MuiBox-root jss1">
                        <div className="MuiBox-root jss2"><StarIcon theme={theme} /></div>
                    </div>
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="dangerHighEmphasis" className="Text-sc-d4qk04-0 elWLSW">{configs.market_price}</div>
                        <div color="foregroundHighEmphasis" className="Text-sc-d4qk04-0 jhXiok">{configs.market_price} {selectCoin}</div>
                    </div>
                    <Divider />
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="foregroundLowEmphasis" className="Text-sc-d4qk04-0 iosmNE">24h Change</div>
                        <div color="dangerHighEmphasis" className="Text-sc-d4qk04-0 jhXiok text-green-700">+{info.change_24h_amount} | +{info.change_24h_percentage}%</div>
                    </div>
                    <Divider />
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="foregroundLowEmphasis" className="Text-sc-d4qk04-0 iosmNE">24h High</div>
                        <div color="foregroundHighEmphasis" className="Text-sc-d4qk04-0 jhXiok">{info.high}</div>
                    </div>
                    <Divider />
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="foregroundLowEmphasis" className="Text-sc-d4qk04-0 iosmNE">24h Low</div>
                        <div color="foregroundHighEmphasis" className="Text-sc-d4qk04-0 jhXiok">{info.low}</div>
                    </div>
                    <Divider />
                    <div className="TradeRatesCard__RatesCard-sc-d8v0i9-4 cAVJor">
                        <div color="foregroundLowEmphasis" className="Text-sc-d4qk04-0 iosmNE">24h Volume ({selectCoin})</div>
                        <div color="foregroundHighEmphasis" className="Text-sc-d4qk04-0 jhXiok">{info.volume}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
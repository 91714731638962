import React, { useRef, useState } from 'react'
import { API_HOST } from '../../fetcher/endpoints';
import axios from 'axios';

const UploadPhoto = ({ title, onUpload, url, token, hideBtn }) => {
    const [imageURL, setImageURL] = useState(url);
    const inputUploadRef = useRef();

    const upload = () => {
        inputUploadRef.current.click();
    }
    const onUploadCompleted = (event) => {
        axios.post(API_HOST + '/admin/rest/upload/media', {
            file: event.target.files[0]
        }, {
            data: {
                file: event.target.files[0]
            },
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary1MXcHDbhHvB1Bf4e'
            }
        }).then(function (response) {
            const { data, status } = response.data;
            if (status === 'success') {
                setImageURL(data.url);
                if(onUpload){
                    onUpload(data.url);
                }
            }
        }).catch(function (error) {
            console.log(error);
        }).finally(function () {
            // always executed
        });

    }
    return (
        <div className="flex flex-col p-8 justify-center items-center">
            {imageURL && <img src={API_HOST + imageURL} width="200px" alt='' />}
            <br />
            {!hideBtn && <div className="cursor-pointer btn-upload" onClick={upload}>{title ?? 'Upload'} </div>}
            <input name="file" type="file" accept="image/*" className="hidden" ref={inputUploadRef} onChange={onUploadCompleted} alt='' />
        </div>

    )
}


export default UploadPhoto;
import React, { useEffect, useState } from "react";
import { fetchOpenP2PRequests } from "../../fetcher";
import Pagination from "../Pagination";
import { ICONS } from "../../config/icons";

const P2PRequest = ({ onDetailRequest }) => {
  const [data, setData] = useState([]);
  const [paging, setPaging] = useState({
    total: 0,
    page: 1,
    pages: 1,
  });

  const loadP2PRequest = async () => {
    const { success, data } = await fetchOpenP2PRequests({ page: paging.page });
    if (success) {
      const { records, paging } = data;
      setData(records);
      setPaging(paging);
    }
  }

  const onChangePaging = (page) => {
    setPaging({ ...paging, page: page });
  }

  useEffect(() => {
    loadP2PRequest();
  }, [paging.page])

  return (
    <table className="monitor-table p-10 w-full">
      <thead>
        <tr>
          <td className="text-header">Coin</td>
          <td className="text-header">Amount</td>
          <td className="text-header">Price</td>
          <td className="text-header">Action</td>
          <td className="text-header">Time</td>
          <td className="text-header">Action</td>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <tr>
            <td colSpan={6}>
              <p className="text-center my-2" style={{ margin: 10 }}> No Records </p>
            </td>
          </tr>
        )}
        {data.map((item) => (
          <tr key={item.id} style={{ height: 25 }}>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'} flex flex-row space-x-2 p-2`}>
              <img src={ICONS[item.coin.toUpperCase()]} alt='logo-icon' width="15px" />
              <span>{item.coin}</span>
            </td>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'}`}>
              <span>{item.quantity}</span> {``}
            </td>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'}`}>{item.price} {item.currency}</td>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'}`}>{item.action.toUpperCase()}</td>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'}`}>{item.datetime_created}</td>

            <td>
              {item.status === "1" && item.allow_order && <button className="btn-p2p-action" onClick={() => onDetailRequest(item.id)}>Detail</button>}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={6}>
            <Pagination total={paging.total} page={paging.page} pages={paging.pages} limit={paging.limit} handleChangePaging={onChangePaging} />
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default P2PRequest;

import { tradeSelectOptions } from '../../constants';
import { useContext } from 'react';
import { fetchUserInfo } from '../../fetcher';
import { useQuery } from 'react-query';
import { AuthContext } from '../../App';
import { userActions } from '../../reducer/userReducer';
import { LightThemIcon, ToggleThemeIcon } from '../icon';
import { OptionsDropdown } from './topup';

export default function Header() {
    const { state, dispatch } = useContext(AuthContext);
    const { theme, selectCoin, wallet } = state.user;

    const changeTheme = () => {
        dispatch({
            type: userActions.setTheme,
            data: {
                theme: theme === 'dark' ? 'light' : 'dark'
            }
        })
    }

    const changeSelectCoin = (coin) => {
        dispatch({
            type: userActions.setSelectCoin,
            data: {
                coin: coin
            }
        })
    }

    useQuery(['fetch_user_info'], () => fetchUserInfo(), {
        onSuccess: (data) => {
            const { wallet } = data;
            if (wallet) {
                dispatch({
                    type: userActions.updateWallet, data: {
                        wallet: wallet
                    }
                })
            }
        }
    });
    return (
        <div className={`header-container mobile`}>
            <div className={`flex flex-col pt-2 pb-2`}>
                <div className='grid grid-cols-2 gap-12'>
                    <div className="trading-header-center-row gap-2">
                        <div className='grid grid-cols-2 text-center justify-center align-middle items-center'>
                            <img src={wallet.newp_img} alt='logo-icon' className="coin-icon flex-1" />
                            <p className="coin-text">{wallet.newp} &nbsp;
                                <small><b>NEWP</b></small>
                            </p>
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="Header__SocialWrapper-sc-1fl4uem-6 khsLS">
                            <button className="ToggleTheme__ToggleThemeWrapper-sc-ukvtqm-0 gAVWQe" onClick={changeTheme}>
                                {theme === 'dark' && <LightThemIcon />}
                                {theme === 'light' && <ToggleThemeIcon />}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-12 mt-2'>
                    <button className="connect-button" scale="md">
                        <select className="select-coin" onChange={(e) => changeSelectCoin(e.target.value)} value={selectCoin}>
                            {
                                tradeSelectOptions.map((option, idx) => <option value={option.value} key={`options-${idx}`}>{option.label}</option>)
                            }
                        </select>
                    </button>
                    <OptionsDropdown />
                </div>
            </div>
        </div>
    )
}
import { useEffect, useState, useContext } from "react";
import { TradingActions, TradingSelects, TradingHistory, sortBy } from "../../constants";
import { get } from "../../fetcher/request";
import { GET_TRADING_REQUESTS } from "../../fetcher/endpoints";
import { AuthContext } from "../../App";
import { userActions } from '../../reducer/userReducer';
import FormAction from "./form";

function TradingContainer() {
    const { dispatch, state } = useContext(AuthContext);
    const { selectCoin, token } = state.user;

    const [action, setAction] = useState(TradingActions.Buy);
    const [selectMarket, setSelectMarket] = useState(TradingSelects.Limit);
    const [selectHistory, setSelectHistory] = useState(TradingHistory.Order);
    const [upperBuyRecords, setUpperBuyRecords] = useState([]);
    const [lowerBuyRecords, setLowerBuyRecords] = useState([]);
    const [openRecords, setOpenRecords] = useState([]);
    const [orderHistoryRecords, setOrderHistoryRecords] = useState([]);
    const [orderCompletedRecords, setOrderCompletedRecords] = useState([]);
    const [marketConfig, setMarketConfig] = useState(0);

    const fetchMarketOrders = async () => {
        const { data, success } = await get(GET_TRADING_REQUESTS, { coin: selectCoin, token: token });
        if (success) {
            const { config, upper_buyer, lower_buyer, open_orders, completed_orders, order_history } = data;
            setMarketConfig(config ?? 0);
            setUpperBuyRecords(sortBy(upper_buyer, 'rate'));
            setLowerBuyRecords(sortBy(lower_buyer, 'rate'));
            setOpenRecords(open_orders);
            setOrderCompletedRecords(completed_orders);
            setOrderHistoryRecords([]);
            dispatch({
                type: userActions.setConfig, data: {
                    configs: config
                }
            });
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            fetchMarketOrders();
        }, 5000);
        fetchMarketOrders();
        return () => clearInterval(interval);
    }, [selectCoin])


    return (
        <div className="trading-container flex  h-screen">
            <div className="flex flex-col">
                <div className="trading-view w-100 h-100 col-span-2">
                    {/* <iframe src="/tradingview" height="650px" className="tradingview-webview" ></iframe> */}
                </div>
                <div className="trading-action w-100 h-100">
                    <div className="trading-btn grid grid-cols-2 gap-2 w-100">
                        <button onClick={() => setAction(TradingActions.Buy)} className={`actions-btn ${TradingActions.Buy === action ? 'action-buy' : ''}`}>{TradingActions.Buy.toUpperCase()}</button>
                        <button onClick={() => setAction(TradingActions.Sell)} className={`actions-btn ${TradingActions.Sell === action ? 'action-sell' : ''}`}>{TradingActions.Sell.toUpperCase()}</button>
                    </div>
                    <div className="trading-select m-t-20">
                        <button onClick={() => setSelectMarket(TradingSelects.Limit)} className={`selects-btn ${TradingSelects.Limit === selectMarket ? 'select-active' : ''}`}>{TradingSelects.Limit}</button>
                        <button onClick={() => setSelectMarket(TradingSelects.Market)} className={`selects-btn ${TradingSelects.Market === selectMarket ? 'select-active' : ''}`}>{TradingSelects.Market}</button>
                    </div>
                    <div className="input-trading-container">
                        <FormAction token={token} action={action} networkFee={marketConfig.network_fee} marketPrice={marketConfig.market_price} selectMarket={selectMarket} />
                    </div>
                </div>

                <div className="trading-monitor w-100 h-100 p-4">
                    <div className="trading-monitor-container">
                        <h3 className="text-heading">Liquidity</h3>
                        <table className="monitor-table p-10">
                            <tbody>
                                <tr>
                                    <td className="text-header">Price</td>
                                    <td className="text-header">Amount</td>
                                    <td className="text-header">Total ({selectCoin})</td>
                                </tr>
                                {upperBuyRecords.map(item => (
                                    <tr key={item.id}>
                                        <td className="text-danger">{item.rate}</td>
                                        <td className="text-number">{item.total}</td>
                                        <td className="text-number">{parseInt(item.total * item.rate * 100) / 100}</td>
                                    </tr>
                                ))}
                                <tr className="border-t border-b">
                                    <td className="text-success font-2x">{marketConfig.market_price}</td>
                                    <td className="text-number font-2x">{marketConfig.market_amount}</td>
                                    <td className="text-number">{parseInt(marketConfig.market_price * marketConfig.market_amount  * 100) / 100}</td>
                                </tr>
                                {lowerBuyRecords.map(item => (
                                    <tr key={item.id}>
                                        <td className="text-success">{item.rate}</td>
                                        <td className="text-number">{item.total}</td>
                                        <td className="text-number">{parseInt(item.total * item.rate * 100) / 100}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="market-trades w-100 h-100  p-4">
                    <div className="trading-monitor-container">
                        <h3 className="text-heading">Market Trades</h3>
                        <table className="monitor-table p-10">
                            <thead>
                                <tr>
                                    <td className="text-header">Price</td>
                                    <td className="text-header">Amount</td>
                                    <td className="text-header">Time</td>
                                </tr>
                            </thead>
                            <tbody>
                                {orderCompletedRecords.map(item => (
                                    <tr key={item.id}>
                                        <td className="text-success">{item.rate}</td>
                                        <td className="text-number">{item.total}</td>
                                        <td className="text-number">{item.completed_datetime}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div className="order-history">
                <div className="trading-select m-t-20 p-4">
                    <button onClick={() => setSelectHistory(TradingHistory.Open)} className={`selects-btn ${TradingHistory.Open === selectHistory ? 'select-active' : ''}`}>Open Orders</button>
                    <button onClick={() => setSelectHistory(TradingHistory.Order)} className={`selects-btn ${TradingHistory.Order === selectHistory ? 'select-active' : ''}`}>Order History</button>
                </div>
                {selectHistory === TradingHistory.Open && (
                    <div className="trading-monitor-container p-4" >
                        <table className="monitor-table p-10">
                            <thead>
                                <tr>
                                    <td className="text-header">Price</td>
                                    <td className="text-header">Amount</td>
                                    <td className="text-header">Time</td>
                                </tr>
                            </thead>
                            <tbody>
                                {openRecords.map(item => (
                                    <tr key={item.id}>
                                        <td className="text-success">{item.rate}</td>
                                        <td className="text-number">{item.total}</td>
                                        <td className="text-number">{item.completed_datetime}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {selectHistory === TradingHistory.Order && (
                    <div className="trading-monitor-container p-4" >
                        <table className="monitor-table p-10">
                            <thead>
                                <tr>
                                    <td className="text-header">Price</td>
                                    <td className="text-header">Amount</td>
                                    <td className="text-header">Time</td>
                                </tr>
                            </thead>
                            <tbody>
                                {orderHistoryRecords.map(item => (
                                    <tr key={item.id}>
                                        <td className="text-success">{item.rate}</td>
                                        <td className="text-number">{item.total}</td>
                                        <td className="text-number">{item.completed_datetime}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}
export default TradingContainer;
/* eslint-disable no-useless-constructor */
import '../styles/App.css';

import React from "react";
import { TradingViewEmbed, widgetType } from "react-tradingview-embed";

function TradingViewPage() {

  const [state, setState] = React.useState({
    times: [],
    high: [],
    low: [],
    chartData: [],
    query: "TRX",
    leaderboard: [],
    addressData: "",
    symbol: ""
  });

  React.useEffect(() => {
    loadChartData();
  }, []);

  const loadChartData = async () => {
    const response = await fetch(
      `https://min-api.cryptocompare.com/data/blockchain/histo/day?fsym=${state.query}&api_key=54c69a67adfc783963d3589c5a08a40a5d619b0f22b94b1c79df9acc9129c5ff&limit=30`
    );
    const data = await response.json();
    const bulkData = data.Data.Data;
    const dataArray = [];
    // eslint-disable-next-line no-lone-blocks
    {
      bulkData.map((y) =>
        dataArray.push({
          x: y.time * 1000,
          y: y.transaction_count * y.average_transaction_value
        })
      );
    }
    setState({
      ...state,
      chartData: dataArray,
      symbol: state.query,
    });
  };


  const { query } = state;

  return (
    <div>
      <div className="inputDiv">
      </div>
      <div className="charty">
        <TradingViewEmbed
          widgetType={widgetType.ADVANCED_CHART}
          widgetConfig={{
            interval: "1H",
            colorTheme: "dark",
            width: "100%",
            symbol: query + "USD",
          }}
        />

      </div>
    </div>
  );
}


export default TradingViewPage;

import "../styles/App.css";
import "../styles/Main.css";
import React, { useContext, useState, useEffect } from "react";
import Header from "../components/Header";
import useRouter from "../hooks/useRouter";
import { AuthContext } from "../App";
import { userActions } from "../reducer/userReducer";
import { BrowserView, MobileView } from "react-device-detect";
import { Cookies } from "react-cookie";
import { DEFAULT_COOKIE_ACCESS_TOKEN } from "../constants";
import HeaderDesktop from "../components/HeaderDesktop";
import { LoginPage } from ".";
import WalletInfo from "../components/WalletInfo";
import InputText from "../components/InputText";
import { useMutation } from "react-query";
import swal from "sweetalert";
import { getFee, withdraw, getTransactions } from "../fetcher";

function WithdrawPage() {
  const cookies = new Cookies();
  const { query } = useRouter();
  const { access_token, coin } = query;

  const { state, dispatch } = useContext(AuthContext);
  const { token, theme, wallet, walletCoin } = state.user;

  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState();
  const [authenCode, setAuthenCode] = useState();
  const [fee, setFee] = useState("");
  const [transactions, setTransactions] = useState([]);

  const fetchFeeWithdraw = async () => {
    const { feeText } = await getFee(
      walletCoin ?? "NEWP",
      wallet.address,
      token,
    );
    setFee(feeText ?? "");
  };

  const fetchTransaction = async () => {
    const { data } = await getTransactions(walletCoin, "withdraw", token);
    setTransactions(data?.records ?? []);
  };

  useEffect(() => {
    dispatch({
      type: userActions.setWalletCoin,
      data: {
        walletCoin: coin ? coin.toUpperCase() : "USDT",
      },
    });
  }, [coin]);

  useEffect(() => {
    fetchFeeWithdraw();
  }, [walletCoin]);

  useEffect(() => {
    if (wallet) {
      fetchTransaction();
      fetchFeeWithdraw();
    }
  }, [wallet]);

  useEffect(() => {
    const token =
      access_token ?? cookies.get(DEFAULT_COOKIE_ACCESS_TOKEN) ?? "";
    dispatch({
      type: userActions.setToken,
      data: {
        token: token,
      },
    });
  }, [access_token, dispatch]);

  const { isLoading, mutate } = useMutation(withdraw, {
    onSuccess: (data) => {
      const { success, msg } = data;
      if (success) {
        swal({
          title: "Withdraw",
          text: "Your request has been sent. Please wait for a few minutes to completed",
          icon: "success",
        });
        setAddress("");
        setAmount();
        fetchTransaction();
      } else {
        swal({
          title: "Withdraw",
          text: msg,
          icon: "error",
        });
      }
    },
    onError: (err) => {
      swal({
        title: "Withdraw",
        text: err.msg,
        icon: "error",
      });
    },
  });
  if (!token) return <LoginPage />;
  return (
    <div className={`App ${theme}-theme`}>
      <MobileView>
        <Header />
      </MobileView>
      <BrowserView>
        <HeaderDesktop />
        <WalletInfo />
      </BrowserView>

      <div className="withdraw-container">
        <MobileView>
          <div className="p-4">
            {walletCoin === "USDT" && (
              <div className="flex-col flex my-10 text-center justify-center align-middle items-center">
                <img
                  src={wallet.usdt_img}
                  alt="logo-icon"
                  className="coin-icon flex-1"
                />
                <p className="coin-text">
                  {wallet.usdt} &nbsp;
                  <small>
                    <b>USDT</b>
                  </small>
                </p>
              </div>
            )}
            {walletCoin === "TRX" && (
              <div className="flex-col flex my-10 text-center justify-center align-middle items-center">
                <img
                  src={wallet.trx_img}
                  alt="logo-icon"
                  className="coin-icon flex-1"
                />
                <p className="coin-text">
                  {wallet.trx} &nbsp;
                  <small>
                    <b>TRX</b>
                  </small>
                </p>
              </div>
            )}
            {walletCoin === "NEWP" && (
              <div className="flex-col flex my-10 text-center justify-center align-middle items-center">
                <img
                  src={wallet.newp_img}
                  alt="logo-icon"
                  className="coin-icon flex-1"
                />
                <p className="coin-text">
                  {wallet.newp} &nbsp;
                  <small>
                    <b>NEWP</b>
                  </small>
                </p>
              </div>
            )}
          </div>
        </MobileView>
        <h3>Withdraw</h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = {
              type: "withdraw",
              address: address,
              coin: walletCoin.toLowerCase(),
              total: amount,
              authenCode: authenCode,
              token,
            };
            mutate(formData);
          }}
          className="form-control"
        >
          <InputText
            placeholder="Enter address"
            type="text"
            value={address}
            onChange={(value) => setAddress(value)}
            required={true}
          />
          <InputText
            placeholder="Enter total number"
            type="number"
            value={amount}
            onChange={(value) => setAmount(parseFloat(value))}
            required={true}
          />
          <InputText
            placeholder="Enter google authenticator code"
            type="text"
            value={authenCode}
            onChange={(value) => setAuthenCode(value)}
            required={true}
          />
          <button
            className="btn submit-btn my-10"
            type="submit"
            disabled={isLoading}
          >
            Withdraw
          </button>
          <p className="font-bold mt-11">
            FEE <a className="App-link"> {fee}</a>
          </p>
        </form>
      </div>
      <div className="trading-monitor-container history-monitor">
        <h3 className="text-heading">Transactions</h3>
        <table className="monitor-table p-10">
          <thead>
            <tr>
              <td className="text-header">Type</td>
              <td className="text-header">Address</td>
              <td className="text-header">Total</td>
              <td className="text-header">Note</td>
              <td className="text-header">Status</td>
              <td className="text-header">Time</td>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.type.toUpperCase()}</td>
                <td>{transaction.to}</td>
                <td className="text-danger">
                  {transaction.out} {transaction.coin.toUpperCase()}
                </td>
                <td className="text-number">{transaction.note}</td>
                <td className="text-number">{transaction.status_text}</td>
                <td className="text-number">{transaction.datetime_created}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WithdrawPage;

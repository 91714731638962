export const tradeSelectOptions = [
    {
        id: 'usdt',
        value: 'USDT',
        label: 'NEWP/USDT'
    },
    {
        id: 'trx',
        value: 'TRX',
        label: 'NEWP/TRX'
    }
]
export const coinSelectOptions = [
    {
        id: 'usdt',
        value: 'USDT',
        label: 'USDT'
    },
    {
        id: 'trx',
        value: 'TRX',
        label: 'TRX'
    },
    {
        id: 'newp',
        value: 'NEWP',
        label: 'NEWP'
    }
]

export const TradingActions = {
    Buy: 'buy',
    Sell: 'sell'
};

export const TradingSelects = {
    Limit: 'Limit',
    Market: 'Market'
};


export const TradingHistory = {
    Open: 'Open',
    Order: 'Order',
    Balances: 'Balances'
};
export const DEFAULT_COOKIE_ACCESS_TOKEN = 'access-token-cookie';


export const sortBy = (data, key) => {
    let result = [];
    data.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1
    });
    data.map((item) => {
        const index = result.findIndex(i => i.rate === item.rate);
        if(index >= 0){
            result[index].total = result[index].total + item.total;
        }else {
            result.push(item);
        }
    })
    
    return result;
}

export const InfoTab = {
    ACCOUNT: "account",
    PASSWORD: "password",
    BANK_ACCOUNT: "bank_account",
    AUTHENTICATOR: "authenticator"
}

// P2P
export const p2pAction = {
    BUY: 'buy',
    SELL: 'sell'
};

export const p2pPaymentMethods = {
    Banking: 'bank_transfer'
};

export const p2pCurrency = {
    USD: 'USD',
    VND: 'VNĐ'
};

export const p2pStatus = {
    Open: 1,
    WaitingForTransaction: 2,
    Closed: 3,
};

export const p2pStatusText = {
    [p2pStatus.Open]: 'Open',
    [p2pStatus.WaitingForTransaction]: 'Open',
    [p2pStatus.Open]: 'Open',
}

export const p2pTab = {
    Open: 'open',
    MyOrder: 'my_order',
    MyRequest: 'my_request'
}

export const p2pView = {
    Order: 'order',
    Request: 'request',
    List: 'list',
}

export const p2pOrderView = {
    Form: 'form',
    List: 'list',
}
export const PaymentMethodEnum = {
    Banking: "bank_transfer",
}

export const PaymentMethodOptions = [
    {
        key: 1,
        label: 'Bank Transfer',
        value: PaymentMethodEnum.Banking
    }
]

export const initialBuyP2P = {
  name: null,
  coin: null,
  price: null,
  quantity: null,
  currency: null,
  lower_limit: 0,
  upper_limit: 0,
};


export const initialSellP2P = {
    name: null,
    coin: null,
    price: null,
    quantity: null,
    currency: null,
    lower_limit: null,
    upper_limit: null,
    payment_method: PaymentMethodEnum.Banking,
  };
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import { auth2FA, getProfile, enable2FA, disable2FA } from "../../fetcher";
import InputText from "../InputText";
import swal from "sweetalert";

const ProfileAuthenticator = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [code, setCode] = useState("");
  const [qrImage, setQRImage] = useState("");
  const [step, setStep] = useState(1);

  const { state } = useContext(AuthContext);
  const { token } = state.user;

  const fetchProfile = async () => {
    setLoading(true);
    const { user } = await getProfile(token);
    setData(user);
    setLoading(false);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    const { success, msg } = await auth2FA(
      {
        code: value,
      },
      token,
    );
    setValue("");
    if (success) {
      swal({
        title: "Update",
        text: "Success",
        icon: "success",
      });
      setStep(1);
      fetchProfile();
    } else {
      swal({
        title: "Update",
        text: msg,
        icon: "error",
      });
    }
    setLoading(false);
  };

  const onDisableSubmit = async () => {
    setLoading(true);
    const { success, msg } = await auth2FA(
      {
        code: value,
      },
      token,
    );
    setValue("");
    if (success) {
      swal({
        title: "Update",
        text: "Success",
        icon: "success",
      });
      onDisable2FA();
    } else {
      swal({
        title: "Authenticator",
        text: msg,
        icon: "error",
      });
    }
    setLoading(false);
  };


  const onDisable2FA = async () => {
    setLoading(true);
    const { success } = await disable2FA();
    if (success) {
      swal({
        title: "Update",
        text: "Success",
        icon: "success",
      });
    } else {
      swal({
        title: "Update",
        text: "Can not update",
        icon: "error",
      });
    }
    fetchProfile();
    setLoading(false);
    setStep(1);
  };

  const enable = async () => {
    setLoading(true);
    const { success, qr_code, code } = await enable2FA(token);
    if (success) {
      setCode(code);
      setQRImage(qr_code);
      setStep(2);
    }
    fetchProfile();
    setLoading(false);
  };

  const toggle2FA = () => {
    if (data.is_google_authenticator === "1") {
      setStep(4);
    } else {
      enable();
    }
  };

  return (
    !loading && (
      <>
        <div
          className="flex flex-row justify-center align-middle items-center"
          style={{ width: 500, margin: "auto", marginTop: 50 }}
        >
          {step === 1 && (
            <>
              <h1
                className="text-white flex justify-center align-middle items-center"
                style={{ marginRight: 50 }}
              >
                2FA Google Authenticator
              </h1>
              <button
                onClick={toggle2FA}
                className=" submit-btn my-10 flex"
                type="submit"
                disabled={loading}
              >
                Turn {data.is_google_authenticator === "1" ? "OFF" : "ON"}
              </button>
            </>
          )}
          {step === 2 && (
            <div className="flex flex-col text-center justify-center items-center">
              <h2 className="text-white">
                Scan this QR code in the Google Authenticator app
              </h2>
              <br />
              <img src={qrImage} width={200} />
              <br />
              <h4 className="text-white">
                If you are unable to scan this QR code, please enter this code
                manually into the app
              </h4>
              <br />
              <h1 className="text-success">{code}</h1>
              <br />
              <button
                className="btn submit-btn my-10"
                onClick={() => setStep(3)}
              >
                Next
              </button>
            </div>
          )}
          {step === 3 && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              className="form-control"
            >
              <InputText
                label="Authenticator Code"
                placeholder="Authenticator Code"
                type="text"
                value={value}
                onChange={(value) => setValue(value)}
                required={true}
              />
              <button
                className="btn success-btn my-10"
                type="submit"
                disabled={loading}
              >
                Finish
              </button>
            </form>
          )}
          {step === 4 && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onDisableSubmit();
              }}
              className="form-control"
            >
              <InputText
                label="Authenticator Code"
                placeholder="Authenticator Code"
                type="text"
                value={value}
                onChange={(value) => setValue(value)}
                required={true}
              />
              <button
                className="btn success-btn my-10"
                type="submit"
                disabled={loading}
              >
                Enter
              </button>
              <a onClick={() => setStep(1)} href
                className="btn btn-danger my-10 text-center text-white"
                style={{ fontSize: 12}}
                type="submit"
                disabled={loading}
              >
                Cancel
              </a>
            </form>
          )}
        </div>
      </>
    )
  );
};

export default ProfileAuthenticator;

import React from "react";
function InfoText(props) {
    return (
        <div className="grid grid-cols-2 pt-2">
            <label className="label-input-text"> {props.label} </label>
            <p className={`input-control label-control text-right ${props.className}`}>
                {props.value}
            </p>
        </div>
    )
}
export default InfoText;
import '../styles/App.css';
import React, { useState, useMemo } from 'react';
import {  useQuery } from 'react-query';
import { activate } from '../fetcher';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

function ActivatePage() {
  const { search } = useLocation();
  const query =  useMemo(() => new URLSearchParams(search), [search]);

  const [account, setAccount] = useState("");
  useQuery(['activate_account'], () => activate(query.get('access_token')), {    onSuccess: data => {
      const { success, email } = data;
      if (success) {
        setAccount(email);
      }
    },
  })

  return (
    <div className="login-container">
      {account !== "" && (
        <div className={`${!isMobile && 'max-width-md'} center login-form`}>
          <img src="https://newex.me/theme/img/logo.png" alt='logo' />
          <p className="font-bold mt-11">
            Hi {account} , Your account was activated.  <a
              className="App-link"
              href="/login"
              rel="noopener noreferrer"
            >
              Sign in
            </a> now
          </p>
        </div>
      )}
    </div>
  );
}

export default ActivatePage;

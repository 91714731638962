import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import { getProfile, updateBankInfo } from "../../fetcher";
import InputText from "../InputText";
import swal from "sweetalert";

const BankAccount = () => {
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AuthContext);
  const { token } = state.user;

  const fetchProfile = async () => {
    setLoading(true);
    const { user } = await getProfile(token);
    setData(user);
    setLoading(false);
  };

  useEffect(() => {
    fetchProfile();
    return () => {};
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    const { success } = await updateBankInfo(
      {
        bank_name: data.bank_name,
        bank_account: data.bank_account,
      },
      token,
    );
    if (success) {
      swal({
        title: "Update",
        text: "Success",
        icon: "success",
      });
      fetchProfile();
    } else {
      swal({
        title: "Update",
        text: "Can not update",
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    !loading && (
      <div className="flex flex-row" style={{ width: 500, margin: "auto" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="form-control"
        >
          <div className="flex flex-col">
            <InputText
              label="Name of Bank"
              placeholder="Name of Bank"
              type="text"
              value={data.bank_name}
              onChange={(value) => setData({ ...data, bank_name: value })}
              required={true}
              disabled={!edit}
            />
            <InputText
              label="Bank Account Number"
              placeholder="Bank Account Number"
              type="text"
              value={data.bank_account}
              onChange={(value) => setData({ ...data, bank_account: value })}
              required={true}
              disabled={!edit}
            />
            {!edit && (
              <a
                href
                className="btn submit-btn my-10"
                onClick={() => setEdit(true)}
              >
                Edit
              </a>
            )}
            {edit && (
              <button
                className="btn success-btn my-10"
                type="submit"
                disabled={loading}
              >
                Update
              </button>
            )}
          </div>
        </form>
      </div>
    )
  );
};

export default BankAccount;

import "../styles/App.css";
import "../styles/Main.css";
import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import useRouter from "../hooks/useRouter";
import { AuthContext } from "../App";
import { userActions } from "../reducer/userReducer";
import { BrowserView, MobileView } from "react-device-detect";
import { Cookies } from "react-cookie";
import { DEFAULT_COOKIE_ACCESS_TOKEN } from "../constants";
import HeaderDesktop from "../components/HeaderDesktop";
import { LoginPage } from ".";
import GameCoinInfo from "../components/GameCoinInfo";
import InputText from "../components/InputText";
import swal from "sweetalert";
import { useMutation, useQuery } from "react-query";
import { depositGame, fetchHistoriesGame, fetchUserInfo, withdrawGame } from "../fetcher";

function GamePage() {
  const cookies = new Cookies();
  const { query } = useRouter();
  const { access_token } = query;
  const [tab, setTab] = useState("deposit");
  const [amount, setAmount] = useState();
  const [withdraw, setWithdraw] = useState();
  const [histories, setHistories] = useState([]);
  const [play, setPlay] = useState(0);
  const [page, setPage] = useState(1);
  const { state, dispatch } = useContext(AuthContext);
  const { token, theme } = state.user;
  const { wallet } = state.user;


  useQuery(['fetch_history_info'], () => fetchHistoriesGame(page), {
    refetchInterval: 10000,
    onSuccess: (data) => {
      const { records } = data;
      setHistories(records && records.length > 0 ? records : [])
    }
  });

  useQuery(['fetch_user_info'], () => fetchUserInfo(), {
    refetchInterval: 5000,
    onSuccess: (data) => {
      const { wallet, play } = data;
      setPlay(play);
      if (wallet) {
        dispatch({
          type: userActions.updateWallet, data: {
            wallet: wallet
          }
        })
      }
    }
  });

  useEffect(
    () =>
      dispatch({
        type: userActions.setToken,
        data: {
          token: access_token ?? cookies.get(DEFAULT_COOKIE_ACCESS_TOKEN) ?? "",
        },
      }),
    [access_token, dispatch],
  );

  const depositMutate = useMutation(depositGame, {
    onSuccess: (data) => {
      const { success, message } = data;
      if (success) {
        setAmount();
        swal({
          title: "Deposit",
          text: "Your request has been completed.",
          icon: "success",
        });
      } else {
        swal({
          title: "Deposit",
          text: message,
          icon: "error",
        });
      }
    },
    onError: (err) => {
      swal({
        title: "Deposit",
        text: err.message,
        icon: "error",
      });
    },
  });
  const withdrawMutate = useMutation(withdrawGame, {
    onSuccess: (data) => {
      const { success, message } = data;
      if (success) {
        setWithdraw();
        swal({
          title: "Withdraw",
          text: "Your request has been completed.",
          icon: "success",
        });
      } else {
        swal({
          title: "Withdraw",
          text: message,
          icon: "error",
        });
      }
    },
    onError: (err) => {
      swal({
        title: "Withdraw",
        text: err.message,
        icon: "error",
      });
    },
  });

  if (!token) return <LoginPage />;
  return (
    <div className={`App ${theme}-theme`}>
      <MobileView>
        <Header />
      </MobileView>
      <BrowserView>
        <HeaderDesktop />
      </BrowserView>
      <GameCoinInfo />
      <div
        className="flex flex-row"
        style={{ marginTop: 120, marginBottom: 20 }}
      >
        <div
          className="withdraw-container flex flex-col"
          style={{ marginTop: 0, padding: 0 }}
        >
          <div className="flex flex-row" style={{ padding: 20 }}>
            <button
              className={`btn ${tab === "deposit" ? "submit-btn" : "disabled-btn"
                }`}
              onClick={() => setTab("deposit")}
            >
              NEWP to xuNEWP
            </button>
            <button
              className={`btn ${tab === "withdraw" ? "submit-btn" : "disabled-btn"
                }`}
              onClick={() => setTab("withdraw")}
            >
              xuNEWP to NEWP
            </button>
          </div>
          {tab === "deposit" && (
            <div style={{ padding: 20 }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (amount > 0) depositMutate.mutate({ total: amount });
                }}
                className="form-control"
              >
                <InputText
                  placeholder="Enter total number"
                  type="number"
                  value={amount}
                  onChange={(value) => setAmount(parseFloat(value))}
                  required={true}
                />
                <button className="btn submit-btn my-10" type="submit">
                  Transfer
                </button>
              </form>
            </div>
          )}
          {tab === "withdraw" && (
            <div style={{ padding: 20 }}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (withdraw > 0) withdrawMutate.mutate({ total: withdraw });
                }}
                className="form-control"
              >
                <InputText
                  placeholder="Enter total number"
                  type="number"
                  value={withdraw}
                  onChange={(value) => setWithdraw(parseFloat(value))}
                  required={true}
                />
                <p>xuNEWP: <b>{wallet.xuNEWP}</b></p>
                <button className="btn submit-btn my-10" type="submit">
                  Transfer
                </button>
              </form>
            </div>
          )}
          <p className="text-white">At the end of the day, the system auto transfers xuNEWP to NEWP.</p>
          <div className="trading-monitor-container" style={{ padding: 10 }}>
            <h3 className="text-heading">Plays : {play} (5 Trx)</h3>
            <h3 className="text-heading">History</h3>
            <table className="monitor-table p-10">
              <thead>
                <tr>
                  <td className="text-header">Note</td>
                  <td className="text-header">Date</td>
                </tr>
              </thead>
              <tbody>
                {histories.map(item => (
                  <tr key={item.id}>
                    <td className={`${item.type === 'deposit' ? 'text-danger' : 'text-success'} cursor-pointer`} >{item.note}</td>
                    <td className="text-number">{item.datetime_created}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-1" style={{ background: "#000", paddingTop:50}}>
          {token && !withdrawMutate.isLoading && !depositMutate.isLoading &&
            <iframe src={`${process.env.REACT_APP_API_HOST}/wheel?token=${token}`} height="450px" className="tradingview-webview" ></iframe>
          }
        </div>
      </div>
    </div>
  );
}

export default GamePage;

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../App";
import { getProfile, updateProfile } from "../../fetcher";
import InputText from "../InputText";
import UploadPhoto from "../UploadPhoto";
import swal from "sweetalert";

const ProfileInfo = () => {
  const [data, setData] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AuthContext);
  const { token } = state.user;

  const fetchProfile = async () => {
    setLoading(true);
    const { user } = await getProfile(token);
    setData(user);
    setLoading(false);
  };

  useEffect(() => {
    fetchProfile();
    return () => {};
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    const { success } = await updateProfile(data, token);
    if (success) {
      swal({
        title: "Update",
        text: "Success",
        icon: "success",
      });
      fetchProfile();
    } else {
      swal({
        title: "Update",
        text: "Can not update",
        icon: "error",
      });
    }
    setLoading(false);
  };

  return (
    !loading && (
      <div className="flex flex-row" style={{ width: 700, margin: "auto" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="form-control"
        >
          <div className="grid grid-cols-2">
            <div className="flex flex-col">
              <InputText
                label="Full name"
                placeholder="Fullname"
                type="text"
                value={data.first_name}
                onChange={(value) => setData({ ...data, first_name: value })}
                required={true}
                disabled={!edit}
              />
              <InputText
                label="Email"
                placeholder="Email"
                type="email"
                value={data.email}
                onChange={(value) => setData({ ...data, email: value })}
                required={true}
                disabled={!edit}
              />
              <InputText
                label="Phone"
                placeholder="Phone"
                type="tek"
                value={data.phone}
                onChange={(value) => setData({ ...data, phone: value })}
                required={true}
                disabled={!edit}
              />
              <InputText
                label="IC Number / Passport"
                placeholder="IC Number/ Passport"
                type="text"
                value={data.ic_number}
                onChange={(value) => setData({ ...data, ic_number: value })}
                required={true}
                disabled={!edit}
              />
              <InputText
                label="Wallet"
                placeholder="Wallet"
                type="text"
                value={data.newp_wallet}
                required={true}
                disabled
              />
              <div className="flex flex-row justify-center">
                <UploadPhoto
                  title="IC Front Image"
                  url={data.front_ic_image}
                  onUpload={(value) => setData({ ...data, front_ic_image: value })}
                  token={token}
                  hideBtn={!edit}
                />
                <UploadPhoto
                  title="IC End Image"
                  url={data.behind_ic_image}
                  onUpload={(value) => setData({ ...data, behind_ic_image: value })}
                  token={token}
                  hideBtn={!edit}
                />
              </div>
            </div>
            <div className="flex flex-col justify-start align-top items-start self-start p-8">
              {data.is_kyc === '1' && <p className="text-2xl text-success text-center">KYC Verified</p>} 
              {data.is_kyc !== '1' && <p className="text-2xl text-danger text-center">You need KYC to trading. Please upload IC photo and profile photo to verify</p>}
              <UploadPhoto
                title="Upload Avatar"
                url={data.logo_img}
                onUpload={(value) => setData({ ...data, logo_img: value })}
                token={token}
                hideBtn={!edit}
              />
              {!edit && (
                <button
                  className="btn submit-btn my-10"
                  onClick={() => setEdit(true)}
                >
                  Edit
                </button>
              )}
              {edit && (
                <button
                  className="btn success-btn my-10"
                  type="submit"
                  disabled={loading}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    )
  );
};

export default ProfileInfo;

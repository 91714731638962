import React, { useState } from "react";
import * as props from "prop-types";
import InputText from "../../InputText";
import SelectInput from "../../Form/SelectInput";
import {
  coinSelectOptions,
} from "../../../constants";

const SellForm = ({ onSubmit, data }) => {
  const [_value, setValue] = useState(data);
  const validate = () => {
    submit();
  };
  const submit = () => {
    if (onSubmit) {
      onSubmit({
        ..._value,
        action: 'sell',
        upper_limit: data.quantity,
      });
    }
  };
  return (
    <>
      <form
        className="text-left"
        onSubmit={(e) => {
          e.preventDefault();
          validate();
        }}
      >
        <InputText
          label="Note"
          placeholder="I want to sell ..."
          type="text"
          value={data.name}
          onChange={(value) => setValue({ ..._value, name: value })}
        />
        <InputText
          label="Total Sell"
          placeholder="Total Sell"
          type="text"
          value={data.quantity}
          onChange={(value) => setValue({ ..._value, quantity: value })}
        />
        <SelectInput
          label="Select Coin"
          options={coinSelectOptions}
          placeholder="Select coin"
          value={data.coin}
          onChange={(value) => setValue({ ..._value, coin: value })}
        />
        <InputText
          label="Minimum to buy"
          placeholder="Min Buy"
          type="number"
          value={data.lower_limit}
          onChange={(value) => setValue({ ..._value, lower_limit: value })}
        />
        <div className="grid grid-cols-2 space-x-2">
          <InputText
            label="Price"
            placeholder="Price"
            type="number"
            value={data.price}
            onChange={(value) => setValue({ ..._value, price: value })}
          />
          <InputText
            label="Currency"
            placeholder="USD"
            type="text"
            value={data.currency}
            onChange={(value) => setValue({ ..._value, currency: value })}
          />
        </div>
        {/* <SelectInput
          label="Payment Methods"
          options={PaymentMethodOptions}
          placeholder="Select payment methods"
          value={data.payment_method}
          onChange={(value) => setValue({ ..._value, payment_method: value })}
        />
        {data.payment_method === PaymentMethodEnum.Banking && (
          <>
            <InputText
              label="Bank Name"
              placeholder="Bank Name"
              type="text"
              value={data.bank_name}
              onChange={(value) => setValue({ ..._value, bank_name: value })}
            />
            <InputText
              label="Bank Account"
              placeholder="Bank Account"
              type="text"
              value={data.bank_account}
              onChange={(value) => setValue({ ..._value, bank_account: value })}
            />
          </>
        )} */}

        <button type="submit" className="btn submit-btn">
          SELL
        </button>
      </form>
    </>
  );
};

SellForm.prototype = {
  onSubmit: props.func,
};
export default SellForm;

import React, { createContext, useReducer } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CookiesProvider } from 'react-cookie';
import {
  Route,
  Routes
} from 'react-router-dom';
import TradingView from './pages/tradingview.jsx';
import {
  ActivatePage,
  HomePage,
  TopupPage,
  WithdrawPage,
  RegisterPage,
  LoginPage,
  DashboardPage,
  ProfilePage,
  P2PPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  GamePage,
} from './pages';
import { rootReducer, initialRoot } from './reducer/index.js';
import "./styles/App.css";
import "./styles/Main.css";


export const AuthContext = createContext();
const queryClient = new QueryClient();

function App() {
  const [state, dispatch] = useReducer(rootReducer, initialRoot);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="tradingview" element={<TradingView />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="topup" element={<TopupPage />} />
            <Route path="withdraw" element={<WithdrawPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="active" element={<ActivatePage />} />
            <Route path="p2p" element={<P2PPage />} />
            <Route path="forgotpassword" element={<ForgotPasswordPage />} />
            <Route path="resetpassword" element={<ResetPasswordPage />} />
            <Route path="game" element={<GamePage />} />
          </Routes>
        </CookiesProvider>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}

export default App;

import "../styles/App.css";
import "../styles/Main.css";
import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import useRouter from "../hooks/useRouter";
import { AuthContext } from "../App";
import { userActions } from "../reducer/userReducer";
import { BrowserView, MobileView } from "react-device-detect";
import { Cookies } from "react-cookie";
import { DEFAULT_COOKIE_ACCESS_TOKEN, InfoTab } from "../constants";
import HeaderDesktop from "../components/HeaderDesktop";
import ProfileInfo from "../components/Profile/info";
import ProfileChangePassword from "../components/Profile/change_password";
import ProfileAuthenticator from "../components/Profile/authenticator";
import BankAccount from "../components/Profile/bank_account";

function ProfilePage() {
  const cookies = new Cookies();
  const [tab, setTab] = useState(InfoTab.ACCOUNT);
  const { query } = useRouter();
  const { access_token } = query;
  const { state, dispatch } = useContext(AuthContext);
  const { token, theme } = state.user;

  useEffect(
    () =>
      dispatch({
        type: userActions.setToken,
        data: {
          token: access_token ?? cookies.get(DEFAULT_COOKIE_ACCESS_TOKEN) ?? "",
        },
      }),
    [access_token, dispatch],
  );

  return (
    token && (
      <div className={`App ${theme}-theme`}>
        <MobileView>
          <Header />
        </MobileView>
        <BrowserView>
          <HeaderDesktop />
        </BrowserView>
        <div className="trading-container flex pt-[110px] h-screen">
          <div className="profile-container">
            <div
              className="flex flex-row justify-center"
              style={{ marginBottom: 50 }}
            >
              <button
                className={`profile-pill ${
                  tab === InfoTab.ACCOUNT ? "active" : ""
                }`}
                onClick={() => setTab(InfoTab.ACCOUNT)}
              >
                Account Information
              </button>
              <button
                className={`profile-pill ${
                  tab === InfoTab.BANK_ACCOUNT ? "active" : ""
                }`}
                onClick={() => setTab(InfoTab.BANK_ACCOUNT)}
              >
                Bank Account
              </button>
              <button
                className={`profile-pill ${
                  tab === InfoTab.PASSWORD ? "active" : ""
                }`}
                onClick={() => setTab(InfoTab.PASSWORD)}
              >
                Change Password
              </button>
              <button
                className={`profile-pill ${
                  tab === InfoTab.AUTHENTICATOR ? "active" : ""
                }`}
                onClick={() => setTab(InfoTab.AUTHENTICATOR)}
              >
                2FA Authenticator
              </button>
            </div>
            <div className="bg-slate-200 col-span-3" style={{ minHeight: 700 }}>
              {tab === InfoTab.ACCOUNT && <ProfileInfo />}
              {tab === InfoTab.BANK_ACCOUNT && <BankAccount />}
              {tab === InfoTab.PASSWORD && <ProfileChangePassword />}
              {tab === InfoTab.AUTHENTICATOR && <ProfileAuthenticator />}
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ProfilePage;

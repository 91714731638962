import { tradeSelectOptions, coinSelectOptions } from '../../constants';
import { useContext, useEffect, useState } from 'react';
import { fetchUserInfo } from '../../fetcher';
import { useQuery } from 'react-query';
import { AuthContext } from '../../App';
import { userActions } from '../../reducer/userReducer';
import { DiscordIcon, Divider, LightThemIcon, TelegramIcon, ToggleThemeIcon, TwitterIcon } from '../icon';
import { OptionsDropdown } from './topup';
import { NavLink } from 'react-bootstrap'

export default function HeaderDesktop() {
    const { state, dispatch } = useContext(AuthContext);
    const url = window.location.href;
    const { theme, walletCoin, selectCoin, token } = state.user;
    const [mode, setMode] = useState('trading'); // [trading, topup, withdraw]
    const [allowGame, setAllowGame] = useState(false);

    useEffect(() => {
        const url = window.location.href;
        if (url.indexOf('topup') > -1) {
            setMode('topup');
        } else if (url.indexOf('withdraw') > -1) {
            setMode('withdraw');
        } else {
            setMode('trading');
        }
    }, []);

    const changeTheme = () => {
        dispatch({
            type: userActions.setTheme,
            data: {
                theme: theme === 'dark' ? 'light' : 'dark'
            }
        })
    }

    const changeSelectCoin = (coin) => {
        dispatch({
            type: userActions.setSelectCoin,
            data: {
                coin: coin
            }
        })
    }

    const changeWalletCoin = (coin) => {
        dispatch({
            type: userActions.setWalletCoin,
            data: {
                walletCoin: coin
            }
        })
    }

    useQuery(['fetch_user_info'], () => fetchUserInfo(), {
        refetchInterval: 10000,
        onSuccess: (data) => {
            const { wallet, allow_game } = data;
            setAllowGame(allow_game);
            if (wallet) {
                dispatch({
                    type: userActions.updateWallet, data: {
                        wallet: wallet
                    }
                })
            }
        }
    });
    

    return (
        <>
            <div className={`header-container`}>
                <p  className="w-full flex absolute top-0 left-0 text-center justify-center" style={{ color : "red", fontSize: 12}}>
                ❌Warning❌ NEWEX trading platform is an international platform, so we DO NOT ENCOURAGE Vietnamese to participate. If you want to participate, you need to be completely voluntary to join our platform and 18 years old enough.
                </p>
                <div className='header-left'>
                    <div className='home-icon'>
                        <a href="/">
                            <img src="https://www.newex.me/theme/img/trader/newp-icon.png" alt='logo-icon' className="coin-icon flex-1" />
                        </a>
                    </div>
                    <div className='navigation-header navigation-list'>
                        <NavLink href='/'><div className={`navigation-list-item  ${url.endsWith('/') && 'selected'}`}>Trade</div></NavLink>
                        <NavLink href='/p2p'><div className={`navigation-list-item ${url.includes('p2p') && 'selected'}`}>P2P</div></NavLink>
                        <NavLink><div className="navigation-list-item">List pair</div></NavLink>
                        {allowGame && 
                            <NavLink href='/game'><div className="navigation-list-item cursor-pointer">Game</div></NavLink>
                        }
                    </div>
                </div>
                <div className="header-right">
                    <div className="Dropdown__DropdownWrapper-sc-ws5mao-0 fONokm menu-dropdown"><button
                        className="StyledButton-sc-19l6nhk-0 kYgjJV IconButton-sc-1wt1b69-0 ExpandableButton__IconButton-sc-hc32bq-0 lagfdI gQEVGL"
                        width="200" scale="md">
                        <div color="foregroundHighEmphasis"
                            className="Text-sc-d4qk04-0 ExpandableButton__CustomText-sc-hc32bq-1 jhXiok jtrFib button-title">
                            <div className="MuiBox-root css-1n2mv2k">Support</div>
                        </div>
                    </button></div>
                    <div className="Dropdown__DropdownWrapper-sc-ws5mao-0 fONokm menu-dropdown"><button
                        className="StyledButton-sc-19l6nhk-0 gXUPeC IconButton-sc-1wt1b69-0 ExpandableButton__IconButton-sc-hc32bq-0 lagfdI gFodHo"
                        width="162" scale="md">
                        <div color="foregroundHighEmphasis"
                            className="Text-sc-d4qk04-0 ExpandableButton__CustomText-sc-hc32bq-1 jhXiok jtrFib button-title">
                            <div className="MuiBox-root css-1n2mv2k">Community</div>
                        </div>
                    </button></div>
                    <Divider />
                    <div className="Header__SocialWrapper-sc-1fl4uem-6 khsLS">
                        <a target="_blank" rel="noreferrer" href="https://discord.com/invite/newp_lab" className="Header__SocialLink-sc-1fl4uem-8 gArGUO">
                            <DiscordIcon theme={theme} />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/newp_lab"
                            className="Header__SocialLink-sc-1fl4uem-8 gArGUO">
                            <TwitterIcon theme={theme} />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://t.me/newp_lab" className="Header__SocialLink-sc-1fl4uem-8 gArGUO">
                            <TelegramIcon theme={theme} />
                        </a>
                    </div>
                    <Divider />
                    <div className="Header__LanguageWrapper-sc-1fl4uem-7 jhajlL">
                        <button className="ToggleTheme__ToggleThemeWrapper-sc-ukvtqm-0 gAVWQe" onClick={changeTheme}>
                            {theme === 'dark' && <LightThemIcon />}
                            {theme === 'light' && <ToggleThemeIcon />}
                        </button>
                    </div>
                    <Divider />
                    {mode === 'trading' && (
                        <button className="connect-button" scale="md">
                            <select className="select-coin" onChange={(e) => changeSelectCoin(e.target.value)} value={selectCoin}>
                                {
                                    tradeSelectOptions.map((option, idx) => <option value={option.value} key={`options-${idx}`}>{option.label}</option>)
                                }
                            </select>
                        </button>
                    )}
                    {['topup', 'withdraw'].includes(mode) && (
                        <button className="connect-button" scale="md">
                            <select className="select-coin" onChange={(e) => changeWalletCoin(e.target.value)} value={walletCoin}>
                                {
                                    coinSelectOptions.map((option, idx) => <option value={option.value} key={`options-${idx}`}>{option.label}</option>)
                                }
                            </select>
                        </button>
                    )}
                    <Divider />
                    <OptionsDropdown />
                </div>
            </div>
        </>
    )
}
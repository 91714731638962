import InputText from '../components/InputText';
import '../styles/App.css';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { forgotPassword } from '../fetcher';
import swal from 'sweetalert';
import { isMobile } from 'react-device-detect';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');

  const { isLoading, mutate } = useMutation(forgotPassword, {
    onSuccess: data => {
      const { status, msg } = data;
      if (status === 'success') {
        swal({
          title: "Success",
          text: "We send you needed information for reseting account",
          icon: "success",
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      } else {
        swal({
          title: "Fail",
          text: msg,
          icon: "error"
        });
      }
    },
    onError: err => {
      swal({
        title: "Fail",
          text: "Unexpected Errors. Please contact the administrator",
          icon: "error"
      });
    }
  })
  const onSubmit = function (e) {
    mutate({ email });
  }


  return (
    <div className="login-container">
      <div className={`${!isMobile && 'max-width-md'} center login-form`}>
        <img src="https://newex.me/theme/img/logo.png" alt='logo' />
        <p className='my-10 heading-text'>
           Forgot password
        </p>
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }} className='form-control'>
          <InputText placeholder="Email" type="email" value={email} onChange={(value) => setEmail(value)} required={true} />
          <button className='btn submit-btn' type='submit' disabled={isLoading}>Submit</button>
          <p className="font-bold mt-11">
            Already have an account? <a
              className="App-link"
              href="/login"
              rel="noopener noreferrer"
            >
              Sign in
            </a>
          </p>
        </form>

      </div>
    </div>
  );
}

export default ForgotPasswordPage;

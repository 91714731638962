import "../styles/App.css";
import "../styles/Main.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import useRouter from "../hooks/useRouter";
import { AuthContext } from "../App";
import { userActions } from "../reducer/userReducer";
import { BrowserView, MobileView } from "react-device-detect";
import { Cookies } from "react-cookie";
import { DEFAULT_COOKIE_ACCESS_TOKEN } from "../constants";
import HeaderDesktop from "../components/HeaderDesktop";
import { LoginPage } from ".";
import WalletInfo from "../components/WalletInfo";
import swal from "sweetalert";
import { getTransactions } from "../fetcher";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CoinInfo from "../components/CoinInfo";

function TopupPage() {
  const cookies = new Cookies();
  const { query } = useRouter();
  const { access_token, coin } = query;

  const { state, dispatch } = useContext(AuthContext);
  const { token, theme, wallet, walletCoin } = state.user;
  const clipboardRef = useRef();
  const [transactions, setTransactions] = useState([]);

  const fetchTransaction = async () => {
    const { data } = await getTransactions(walletCoin, "deposit", token);
    setTransactions(data?.records ?? []);
  };

  useEffect(() => {
    dispatch({
      type: userActions.setWalletCoin,
      data: {
        walletCoin: coin ? coin.toUpperCase() : "USDT",
      },
    });
  }, [coin]);

  useEffect(() => {
    if (wallet) {
      fetchTransaction();
    }
  }, [wallet]);

  useEffect(() => {
    const token =
      access_token ?? cookies.get(DEFAULT_COOKIE_ACCESS_TOKEN) ?? "";
    dispatch({
      type: userActions.setToken,
      data: {
        token: token,
      },
    });
  }, [access_token, dispatch]);

  if (!token) return <LoginPage />;
  return (
    <div className={`App ${theme}-theme`}>
      <MobileView>
        <Header />
      </MobileView>
      <BrowserView>
        <HeaderDesktop />
        <WalletInfo />
      </BrowserView>
      <div className="topup-container">
        <img
          src={`https://chart.googleapis.com/chart?chs=200x200&cht=qr&choe=UTF-8&chl=${wallet.address}`}
          width={200}
          height={200}
          className="border-r-8"
        />
        <textarea
          disabled={true}
          ref={clipboardRef}
          value={`${wallet.address}`}
        />
        <MobileView>
          <>
            {walletCoin === "USDT" && (
              <div className=" text-center justify-center align-middle items-center">
                <img
                  src={wallet.usdt_img}
                  alt="logo-icon"
                  className="coin-icon flex-1"
                />
                <p className="coin-text">
                  {wallet.usdt} &nbsp;
                  <small>
                    <b>USDT</b>
                  </small>
                </p>
              </div>
            )}
            {walletCoin === "TRX" && (
              <div className=" text-center justify-center align-middle items-center">
                <img
                  src={wallet.trx_img}
                  alt="logo-icon"
                  className="coin-icon flex-1"
                />
                <p className="coin-text">
                  {wallet.trx} &nbsp;
                  <small>
                    <b>TRX</b>
                  </small>
                </p>
              </div>
            )}
            {walletCoin === "NEWP" && (
              <div className="text-center justify-center align-middle items-center">
                <img
                  src={wallet.newp_img}
                  alt="logo-icon"
                  className="coin-icon flex-1"
                />
                <p className="coin-text">
                  {wallet.newp} &nbsp;
                  <small>
                    <b>NEWP</b>
                  </small>
                </p>
              </div>
            )}
          </>
        </MobileView>
        {wallet.address && <p className="text-address">{wallet.address}</p>}
        <CopyToClipboard
          text={wallet.address}
          onCopy={() => {
            swal({
              title: "Success",
              text: "Copy address " + wallet.address,
              icon: "success",
            });
          }}
        >
          <button>Copy</button>
        </CopyToClipboard>
        {/* <button type='submit' className='btn' onClick={copyToClipboard}>Copy</button> */}
        <p>
          This address can only accept {walletCoin} (TRC20). To prevent asset
          lost, please do NOT send other currencies into this address.
        </p>
        <br />
        <a href="/dashboard">Back to Dashboard</a>
      </div>
      <div className="trading-monitor-container history-monitor">
        <h3 className="text-heading">Transactions</h3>

        <table className="monitor-table p-10">
          <thead>
            <tr>
              <td className="text-header">Type</td>
              <td className="text-header">Address</td>
              <td className="text-header">Total</td>
              <td className="text-header">Note</td>
              <td className="text-header">Status</td>
              <td className="text-header">Time</td>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td className="text-number">{transaction.type.toUpperCase()}</td>
                <td className="text-number">{transaction.from}</td>
                <td className="text-success">
                  {transaction.in} {transaction.coin.toUpperCase()}
                </td>
                <td className="text">{transaction.note}</td>
                <td className="text-number">{transaction.status_text}</td>
                <td className="text-number">{transaction.datetime_created}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TopupPage;

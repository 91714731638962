import { useEffect, useState, useContext } from "react";
import { TradingSelects, TradingActions } from "../../constants";
import { post } from "../../fetcher/request";
import { AuthContext } from "../../App";
import { BUY_REQUEST, SELL_REQUEST } from "../../fetcher/endpoints";
import swal from "sweetalert";

const FormAction = ({
  marketPrice,
  selectMarket,
  action,
  networkFee,
  token,
}) => {
  const { state } = useContext(AuthContext);
  const { selectCoin, wallet } = state.user;

  const [price, setPrice] = useState();
  const [total, setTotal] = useState();

  const incrementPrice = () => {
    if (TradingSelects.Market !== selectMarket)
      setPrice((prev) => parseInt(prev ?? 0) + 1);
  };
  const decrementPrice = () => {
    if (TradingSelects.Market !== selectMarket && price > 0) {
      setPrice((prev) => parseInt(prev ?? 0) - 1);
    }
  };
  const incrementTotal = () => {
    setTotal((prev) => parseInt(prev ?? 0) + 1);
  };
  const decrementTotal = () => {
    if (total > 0) {
      setTotal((prev) => parseInt(prev ?? 0) - 1);
    }
  };
  const onAction = async () => {
    try {
      const data = {
        coin: selectCoin,
        newp: total,
        price: price,
      };

      setTotal(0);
      if (action === TradingActions.Buy || action === TradingActions.Sell) {
        const { success, msg } = await post(
          action === TradingActions.Buy ? BUY_REQUEST : SELL_REQUEST,
          data,
          token,
        );
        if (success) {
          swal({
            title: "Success",
            text: "Success",
            icon: "success",
          });
        } else {
          swal({
            title: "Error",
            text: msg,
            icon: "error",
          });
        }
      }
    } catch {
      setTotal(0);
      swal({
        title: "Error",
        text: "Fail Trasactions",
        icon: "error",
      });
    }
  };
  useEffect(() => {
    if (selectMarket === TradingSelects.Market) {
      setPrice(marketPrice ?? 0);
    }
  }, [selectMarket]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="form-control-trading">
        <span className="icon-left" onClick={decrementPrice}>
          <svg
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
            className="Svg-sc-10nuzln-0 botfKF"
          >
            <path
              d="M9.33382 1.6668H0.000488281V0.333466H9.33382V1.6668Z"
              fill="#FFFFFF"
            ></path>
          </svg>
        </span>
        <input
          disabled={TradingSelects.Market === selectMarket}
          placeholder="Price"
          type="text"
          className="input-trading"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <span className="icon-right" onClick={incrementPrice}>
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            className="Svg-sc-10nuzln-0 botfKF"
          >
            <path
              d="M9.33382 5.6668H5.33382V9.6668H4.00049V5.6668H0.000488281V4.33347H4.00049V0.333466H5.33382V4.33347H9.33382V5.6668Z"
              fill="#FFFFFF"
            ></path>
          </svg>
        </span>
      </div>
      <div className="form-control-trading">
        <span className="icon-left" onClick={decrementTotal}>
          <svg
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
            className="Svg-sc-10nuzln-0 botfKF"
          >
            <path
              d="M9.33382 1.6668H0.000488281V0.333466H9.33382V1.6668Z"
              fill="#FFFFFF"
            ></path>
          </svg>
        </span>
        <input
          placeholder="Amount"
          type="text"
          className="input-trading"
          value={total}
          onChange={(e) => setTotal(e.target.value)}
        />
        <span className="icon-right" onClick={incrementTotal}>
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            className="Svg-sc-10nuzln-0 botfKF"
          >
            <path
              d="M9.33382 5.6668H5.33382V9.6668H4.00049V5.6668H0.000488281V4.33347H4.00049V0.333466H5.33382V4.33347H9.33382V5.6668Z"
              fill="#FFFFFF"
            ></path>
          </svg>
        </span>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <p className="text-left text-header font-small">Available balance</p>
        {action === TradingActions.Buy && (
          <p className="text-right text-header font-small">
            {wallet[selectCoin.toLowerCase()]} {selectCoin}
          </p>
        )}
        {action === TradingActions.Sell && (
          <p className="text-right text-header font-small">
            {`${wallet.newp} NEWP`}{" "}
          </p>
        )}
      </div>
      <button onClick={onAction} className="btn submit-btn">
        {action.toUpperCase()}
      </button>
      <div className="grid grid-cols-2 gap-2 mt-5">
        <p className="text-left text-header font-small">Network fee</p>
        <p className="text-right text-header font-small">
          {" "}
          {networkFee} TRX (~$0.15 USDT)
        </p>
      </div>
    </form>
  );
};
export default FormAction;

import InputText from "../components/InputText";
import "../styles/App.css";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { register } from "../fetcher";
import swal from "sweetalert";
import { isMobile } from "react-device-detect";

function RegisterPage() {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [check, setCheck] = useState(false);

  const { isLoading, mutate } = useMutation(register, {
    onSuccess: (data) => {
      const { status, msg } = data;
      if (status === "success") {
        swal({
          title: "Register",
          text: "Success",
          icon: "success",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      } else {
        swal({
          title: "Signin",
          text: msg,
          icon: "error",
        });
      }
    },
    onError: (err) => {
      swal({
        title: "Register",
        text: "Fail",
        icon: "error",
      });
    },
  });
  const onSubmit = function (e) {
    mutate({ phone, email, password });
  };

  return (
    <div className="login-container">
      <div className={`${!isMobile && "max-width-md"} center login-form`}>
        <img src="https://newex.me/theme/img/logo.png" alt="logo" />
        <p className="my-10 heading-text">Register</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="form-control"
        >
          <InputText
            placeholder="Email"
            type="email"
            value={email}
            onChange={(value) => setEmail(value)}
            required={true}
          />
          <InputText
            placeholder="Phone"
            type="phone"
            value={email}
            onChange={(value) => setPhone(value)}
            required={true}
          />
          <InputText
            placeholder="Password"
            type="password"
            value={password}
            onChange={(value) => setPassword(value)}
            required={true}
          />
          <div className="flex flex-row">
            <input
              type="checkbox"
              name="policy_apporve"
              onChange={(e) => {
                setCheck(e.target.checked);
              }}
              value={check}
              style={{ border: 0, outline: "none" }}
              required
            />
            <p>
              By creating an account, I agree to{" "}
              <a className="App-link" href="https://newex.me/privacy_policy.html" target="_blank" rel="noopener noreferrer">
                {" "}
                Newex's Terms of Service and Privacy Policy.
              </a>
            </p>
          </div>

          <button className={`btn  ${check ? 'submit-btn': 'disabled-btn'}`} type="submit" disabled={isLoading || !check}>
            Create an account 
          </button>
          <p className="font-bold mt-11">
            Already have an account?{" "}
            <a className="App-link" href="/login" rel="noopener noreferrer">
              Sign in
            </a>{" "}
            now
          </p>
        </form>
      </div>
    </div>
  );
}

export default RegisterPage;

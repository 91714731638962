import React, { useEffect, useState } from "react";
import { cancelP2PRequest, fetchMyP2PRequests, fetchOpenP2PMyRequests } from "../../fetcher";
import Pagination from "../Pagination";
import swal from "sweetalert";
import { ICONS } from "../../config/icons";

const P2PMyRequest = ({ onDetailRequest }) => {
  const [data, setData] = useState([]);
  const [paging, setPaging] = useState({
    total: 0,
    page: 1,
    pages: 1,
  });

  const loadP2PMyRequest = async () => {
    const { success, data } = await fetchMyP2PRequests({ page: paging.page });
    if (success) {
      const { records, paging } = data;
      setData(records);
      setPaging(paging);
    }
  }

  const onChangePaging = (page) => {
    setPaging({ ...paging, page: page });
  }

  const onCancel = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Do you want to cancel this request ?')) {
      const { success, msg } = await cancelP2PRequest({
        id: id
      })
      if (success) {
        swal({
          title: 'P2P',
          text: "Success",
          icon: 'success'
        })
        loadP2PMyRequest();
      } else {
        swal({
          title: 'P2P',
          text: msg,
          icon: 'error'
        })
      }
    }

  }

  useEffect(() => {
    loadP2PMyRequest();
  }, [paging.page])

  return (
    <table className="monitor-table p-10 w-full">
      <thead>
        <tr>
          <td className="text-header">Coin</td>
          <td className="text-header">Amount</td>
          <td className="text-header">Price</td>
          <td className="text-header">Action</td>
          <td className="text-header">Status</td>
          <td className="text-header">Time</td>
          <td className="text-header">Action</td>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <tr>
            <td colSpan={7}>
              <p className="text-center my-2" style={{ margin: 10}}> No Records </p>
            </td>
          </tr>
        )}
        {data.map((item) => (
          <tr key={item.id} style={{ height: 25 }}>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'} flex flex-row space-x-2 p-2`}>
              <img src={ICONS[item.coin.toUpperCase()]} alt='logo-icon' width="15px" />
              <span>{item.coin.toUpperCase()}</span>
            </td>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'}`}>
              <span>{item.quantity}</span> {``}
            </td>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'}`}>{item.price} {item.currency}</td>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'}`}>{item.action.toUpperCase()}</td>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'}`}>{item.status_text}</td>
            <td className={`${item.action === 'buy' ? 'text-success' : 'text-danger'}`}>{item.datetime_created}</td>

            <td>
              <div className="flex flex-row space-x-2">
                {item.status !== '3' && <button className="btn-p2p-action" onClick={() => onCancel(item.id)}>Cancel</button>}
                {item.status === '1' && <button className="btn-p2p-action" onClick={() => onDetailRequest(item.id)}>Detail</button>}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={7}>
            <Pagination total={paging.total} page={paging.page} pages={paging.pages} limit={paging.limit} handleChangePaging={onChangePaging} />
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default P2PMyRequest;

import InputText from "../components/InputText";
import "../styles/App.css";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { login } from "../fetcher";
import swal from "sweetalert";
import { Cookies } from "react-cookie";
import { DEFAULT_COOKIE_ACCESS_TOKEN } from "../constants";
import { isMobile } from "react-device-detect";

function LoginPage() {
  const cookie = new Cookies();
  const [mode, setMode] = useState("userlogin"); // userlogin, 2fa;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authenCode, setAuthenCode] = useState("");

  const onLoginAction = useMutation(login, {
    onSuccess: (data) => {
      const { status, currentUser, msg, check_authen } = data;
      if (status === "success") {
        if (check_authen) {
          setMode("2fa");
          return true;
        }
        if (currentUser) {
          cookie.set(DEFAULT_COOKIE_ACCESS_TOKEN, currentUser.authdata, {
            maxAge: 3600 * 12,
          });
          swal({
            title: "Signin",
            text: "Success",
            icon: "success",
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        }
      } else {
        swal({
          title: "Signin",
          text: msg,
          icon: "error",
        });
      }
    },
    onError: (err) => {
      swal({
        title: "Signin",
        text: err.msg,
        icon: "error",
      });
    },
  });

  const onSubmit = function (e) {
    onLoginAction.mutate({ email, password, authenCode });
  };

  return (
    <div className="login-container">
      <div className={`${!isMobile && "max-width-md"} center login-form`}>
        <img src="https://newex.me/theme/img/logo.png" alt="logo" />
        <p className="my-10 heading-text">Sign in</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="form-control"
        >
          {mode === "userlogin" && (
            <>
              <InputText
                placeholder="Email"
                type="email"
                value={email}
                onChange={(value) => setEmail(value)}
                required={true}
              />
              <InputText
                placeholder="Password"
                type="password"
                value={password}
                onChange={(value) => setPassword(value)}
                required={true}
              />
              <button
                className="btn submit-btn my-10"
                type="submit"
                disabled={onLoginAction.isLoading}
              >
                Sign in
              </button>
            </>
          )}
          {mode === "2fa" && (
            <>
              <p>You need to enter google authenticate code to continue</p>
              <InputText
                placeholder="Please enter google authenticator"
                type="text"
                value={authenCode}
                onChange={(value) => setAuthenCode(value)}
                required={true}
              />
              <button
                className="btn submit-btn my-10"
                type="submit"
                disabled={onLoginAction.isLoading}
              >
                Submit
              </button>
              <p className="font-bold mt-11">
                <span
                  className="App-link cursor-pointer"
                  onClick={() => {
                    setMode('userlogin');
                    setAuthenCode('')
                  }}
                  rel="noopener noreferrer"
                >
                  Back
                </span>
              </p>
            </>
          )}
          {mode === "userlogin" && (
            <>
              <p className="font-bold mt-11">
                Have no an account ?{" "}
                <a
                  className="App-link"
                  href="/register"
                  rel="noopener noreferrer"
                >
                  Sign up
                </a>
              </p>
              <p className="font-bold mt-11">
                <a
                  className="App-link"
                  href="/forgotpassword"
                  rel="noopener noreferrer"
                >
                  Forgot your password
                </a>
              </p>
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default LoginPage;

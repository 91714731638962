import { useParams, useLocation } from "react-router-dom";
import { useMemo} from "react";
import queryString from 'query-string';

export default function useRouter() {
    const params = useParams();
    const location = useLocation();
    return useMemo(() => {
      return {
        pathname: location.pathname,
        query: {
          ...queryString.parse(location.search), // Convert string to object
          ...params,
        },
        location,
      };
    }, [params, location]);
  }
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import { Divider, StarIcon } from '../icon';
import { post } from '../../fetcher/request';
import { GET_REPORT_BY_COIN } from '../../fetcher/endpoints';

export default function GameCoinInfo() {
    const { state } = useContext(AuthContext);
    const { wallet, theme, selectCoin, configs, token } = state.user;
    const [info, setInfo] = useState({});

    const loadInfo = async () => {
        const response = await post(GET_REPORT_BY_COIN, { coin: selectCoin }, token);
        if (response.success) {
            setInfo(response.data);
        }
    }

    useEffect(() => {
        loadInfo()
    }, []);

    return (
        <div className='coin-info'>
            <div className='flex flex-row coin-price'>
                <div className="trading-header-center-row p-2 gap-2">
                    <div className='flex flex-row text-center justify-center align-middle items-center space-x-2'>
                        <img src={wallet.newp_img} alt='logo-icon' className="coin-icon flex" />
                        <b className="coin-text">{Number(wallet.newp)} &nbsp;
                            <small><b>NEWP</b></small>
                        </b>
                    </div>
                </div>
                <div className="trading-header-center-row p-2 gap-2">
                    <div className='flex flex-row text-center justify-center align-middle items-center space-x-2'>
                        <img src={wallet.newp_img} alt='logo-icon' className="coin-icon flex" />
                        <b className="coin-text">{Number(wallet.xuNEWP)} &nbsp;
                            <small><b>xuNEWP</b></small>
                        </b>
                    </div>
                </div>
                <div className="trading-header-center-row p-2 gap-2">
                    <div className='flex flex-row text-center justify-center align-middle items-center space-x-2'>
                        <img src={wallet.trx_img} alt='logo-icon' className="coin-icon flex" />
                        <b className="coin-text">{Number(wallet.trx)} &nbsp;
                            <small><b>TRX</b></small>
                        </b>
                    </div>
                </div>
            </div>
        </div>
    )
}